import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme }): string => theme.checkboxIconColor};
  stroke-width: 3px;
`;

const CheckboxIcon: React.FC = () => (
  <Icon viewBox='0 0 24 24'>
    <polyline points='20 6 9 17 4 12' />
  </Icon>
);

export default CheckboxIcon;
