import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Tabs, TabList, Tab } from '@vygruppen/spor-react';
import { Signal, HistoryData } from 'api/apiDataTypes';
import getSensorHistoryForGroup from 'api/getSensorHistoryForGroup';
import SliderSwitch from 'shared/components/SliderSwitch/SliderSwitch';
import IconAndTextItem from 'shared/components/IconAndTextItem/IconAndTextItem';
import { getTimestampInSeconds } from 'shared/utils/dateUtils';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';
import {
  mapToDescriptionId,
  returnIconName,
} from '../Graph/GraphTypeConverters';
import { Graph } from '../Graph/Graph';
import { GroupDto } from '../Graph/GraphTypes';
import SensorContainer from '../SensorContainer/SensorContainer';
import { AlertIcon } from '../../../../shared/components/Icons/Icons';

const DataVisualizationContainer = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  padding: 12px;
  background-color: ${({ theme }): string => `${theme.primaryBackground}`};
  border: 1px solid ${({ theme }): string => `${theme.defaultCardBorderColor}`};
`;

const Container = styled.div`
  margin-left: 10px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const TabsContainer = styled.div`
  max-width: 400px;
  margin: 22px 0px 22px;
`;

type DataVisualizationProps = {
  vehicleSetId: string;
  sensorSignals?: Signal[];
  historyGroup: GroupDto;
};

const timeOptions = [
  {
    id: 'dataVisualization.timePeriod.oneDay',
    value: 86400,
  },
  {
    id: 'dataVisualization.timePeriod.oneWeek',
    value: 604800,
  },
  {
    id: 'dataVisualization.timePeriod.oneMonth',
    value: 2592000,
  },
  {
    id: 'dataVisualization.timePeriod.twoMonths',
    value: 5184000,
  },
];

export const DataVisualization: React.FC<DataVisualizationProps> = ({
  vehicleSetId,
  historyGroup,
  sensorSignals,
}) => {
  const [warning, setWarning] = useState(false);
  const [showSensorValues, toggleShowSensorValues] = useState(true);
  const [timePeriod, setTimePeriod] = useState(timeOptions[1].value);

  const sensorGroup = historyGroup.groupName;
  const timestamp = getTimestampInSeconds(timePeriod);
  const extendedHistoryData = useQuery<HistoryData>(
    ['updatedHistoryData', vehicleSetId, sensorGroup, timePeriod],
    () =>
      getSensorHistoryForGroup({
        vehicleSetId,
        sensorGroup,
        timestamp,
      }),
    { enabled: timePeriod > timeOptions[0].value },
  );

  const onClick = () => {
    toggleShowSensorValues(!showSensorValues);
  };

  const onTimePeriodChange = (index: number) => {
    setTimePeriod(timeOptions[index].value);
  };

  const extendedHistoryGroup = extendedHistoryData?.data?.groups?.[0];

  return (
    <>
      <DataVisualizationContainer>
        <>
          <HeadingContainer>
            <IconAndTextItem
              width='200px'
              margin='0px 0px 12px 0px'
              data-testid='iconAndText'
              icon={returnIconName(sensorGroup)}
            >
              <b>
                {' '}
                <FormattedMessage
                  id={mapToDescriptionId(sensorGroup)}
                  defaultMessage='Legg til på hjemskjerm'
                />
              </b>
            </IconAndTextItem>
            {warning && <AlertIcon marginRight='20px' marginTop='10px' />}
          </HeadingContainer>
          <TabsContainer>
            <Tabs
              marginLeft={useWindowDimensions().width > 450 ? '46px' : '0px'}
              variant='square'
              colorScheme='accent'
              size='md'
              isFitted
              onChange={onTimePeriodChange}
              defaultIndex={1}
            >
              <TabList>
                {timeOptions.map((option) => (
                  <Tab key={option.id} lineHeight={0.8}>
                    <FormattedMessage id={option.id} />
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </TabsContainer>
          {historyGroup.signals.length > 0 && (
            <Graph
              historyData={extendedHistoryGroup || historyGroup}
              setWarning={setWarning}
              timePeriod={timePeriod * 1000}
            />
          )}
        </>

        {sensorSignals && (
          <>
            <Container>
              <SliderSwitch
                title='Show sensor value'
                isChecked={showSensorValues}
                onChange={onClick}
                checkedTextId='dataVisualization.hideSensorValues'
                checkedDefaultText='Skjul tall for sensorikk'
                uncheckedTextId='dataVisualization.showSensorValues'
                uncheckedDefaultText='Vis tall for sensorikk'
              />
            </Container>
            {showSensorValues && (
              <SensorContainer
                sensorGroup={sensorGroup}
                vehicleSetId={vehicleSetId}
                sensorData={sensorSignals}
              />
            )}
          </>
        )}
      </DataVisualizationContainer>
    </>
  );
};

export default DataVisualization;
