import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createRoot } from 'react-dom/client';
import { SporProvider, Language } from '@vygruppen/spor-react';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AppProvider from './app/AppProvider';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root')!);

root.render(
  <AppProvider>
    <QueryClientProvider client={queryClient}>
      <SporProvider language={Language.NorwegianBokmal}>
        <App />
      </SporProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </AppProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
