import React, { ReactNode } from 'react';
import { FlexContainer, FlexItem } from 'shared/components/Layout/Flex/Flex';
import { AppIcon } from '../Icons/Icons';

interface Props {
  icon: string;
  margin?: string;
  children?: ReactNode;
  rowDirection?: string;
  width?: string;
}

/**
 * This component only needs an icon and wrapped around text to make it univeral across the app.
 */
const IconAndTextItem: React.FC<Props> = (props: Props) => {
  const { margin, icon, children, width, rowDirection } = props;
  return (
    <FlexContainer margin={margin} width={width} direction={rowDirection}>
      <AppIcon type={icon} marginRight='10px' />
      <FlexItem margin='0'>{children}</FlexItem>
    </FlexContainer>
  );
};

IconAndTextItem.defaultProps = {
  rowDirection: 'row',
  margin: '1rem',
};

export default IconAndTextItem;
