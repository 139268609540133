import * as colors from '../../styles/colors';

export const defaultTheme = {
  sliderSwitchHolderBorderColor: colors.blueGrey40,

  sliderSwitchCircleBackground: colors.blueGrey40,
  sliderSwitchCheckedCircleBackground: colors.blueGrey40,

  sliderSwitchCheckedHolderBorderColor: colors.blueGrey40,
  sliderSwitchCheckedHolderBackground: colors.backgroundWhite,
};
