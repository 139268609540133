import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';

export default function useOnClickOutside(
  ref: MutableRefObject<HTMLDivElement | null>,
  handler: Dispatch<SetStateAction<boolean>>,
): void {
  useEffect(() => {
    const listener = (event: any): void => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return (): void => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}
