import { differenceInMinutes, format, parseISO } from 'date-fns';
import React from 'react';
import { nb } from 'date-fns/locale';
import { Text } from 'shared/styles/texts';
import { TimeFieldContainer } from './styles';

const isDelayed = (scheduledTime: string, actualTime: string) => {
  return (
    differenceInMinutes(parseISO(scheduledTime), parseISO(actualTime)) >= 1
  );
};

interface TimeFieldProps {
  scheduledTime: string;
  estimatedTime: string;
  actualTime: string;
  title: string;
  location: string;
}

const TrainTimeField = ({
  scheduledTime,
  estimatedTime,
  actualTime,
  title,
  location,
}: TimeFieldProps) => {
  if (scheduledTime == null) return <div />;

  const newTime =
    estimatedTime && actualTime == null ? estimatedTime : actualTime;

  let delayed = false;

  if (newTime) {
    delayed = isDelayed(scheduledTime, newTime);
  }

  const parsedScheduledTime = parseISO(scheduledTime);

  return (
    <TimeFieldContainer>
      <Text>{title}: </Text>
      &nbsp;
      <Text style={{ textDecoration: delayed ? 'line-through' : 'none' }}>
        {!Number.isNaN(parsedScheduledTime.getTime()) &&
          format(parsedScheduledTime, 'HH:mm', {
            locale: nb,
          })}
      </Text>
      &nbsp;
      {delayed && newTime && (
        <Text style={{ color: 'red' }}>
          {format(parseISO(newTime), 'HH:mm', {
            locale: nb,
          })}
          &nbsp;
        </Text>
      )}
      <Text>{location}</Text>
    </TimeFieldContainer>
  );
};

export default TrainTimeField;
