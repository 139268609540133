import React from 'react';
import styled, { keyframes } from 'styled-components';

type LoadingProps = { loading: boolean; style?: any };

interface LoadingSpinnerProps {
  width?: string;
}

const flow = keyframes`
    0%{background-position: 0% 50%}
    50%{background-position: 50% 100%}
`;

export const LoadingSpinner = styled.div`
  background: linear-gradient(
    -45deg,
    #8eacd585,
    #889bb259,
    #dce4ef,
    #dfd2d2,
    #b0b2b6
  );
  animation: ${flow} 1.5s ease-in infinite;
  background-size: 600%;
  -webkit-text-fill-color: transparent;
  border-radius: 0.5rem;
  height: 0.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: ${(props: LoadingSpinnerProps) =>
    props.width ? props.width : '100%'};
`;

export const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  const { loading, style } = props;

  if (loading) {
    return (
      <div data-testid='loading-spinner' style={style}>
        <LoadingSpinner />
      </div>
    );
  }
  return <div />;
};

const FullPageLoaderContainer = styled.div`
  padding: 20px;
`;

export const FullPageLoader = () => (
  <FullPageLoaderContainer>
    <Loading loading />
  </FullPageLoaderContainer>
);
