import React from 'react';

import SensorList from 'features/SensorList/SensorList';
import {
  hasValidSensorGroup,
  processSensorData,
} from 'shared/utils/sensorUtils';
import { Signal } from 'api/apiDataTypes';

type SensorContainerProps = {
  vehicleSetId: string;
  sensorData: Signal[];
  sensorGroup?: string;
};

const SensorContainer: React.FC<SensorContainerProps> = (props) => {
  const { vehicleSetId, sensorData, sensorGroup } = props;

  const sensors = sensorData.filter(hasValidSensorGroup);
  const processedSensors = sensors.map(processSensorData);
  return (
    <SensorList
      vehicleSetId={vehicleSetId}
      sensorGroup={sensorGroup}
      processedSensors={processedSensors}
    />
  );
};

export default SensorContainer;
