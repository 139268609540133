import { SensorGroup, SensorData, Signal } from 'api/apiDataTypes';
import { format } from 'date-fns';

export interface VehicleData {
  vehicleSetId: string;
  processedSensors: ExpandedSignal[];
}
export interface Sensor {
  group: SensorGroup;
  carriage: string;
}

export interface ExpandedSignal {
  sensor: Sensor;
  value: string;
  timestamp: string;
  isPastTreshold: boolean;
  old?: boolean;
}

export enum SensorThresholds {
  water = 25,
  septic = 75,
  batteryVoltage = 100,
  batteryChargerVoltage = 100,
  outdoorTemperature = 0,
  indoorTemperature = 0,
}

export const onSensorThreshold = (
  valueNow: string,
  sensorType: SensorGroup,
): boolean => {
  let reachedThreshold = false;
  const septicThreshold = 75;
  const waterThreshold = 25;
  const batteryVoltageTreshold = 100;
  if (
    sensorType === SensorGroup.septic &&
    parseInt(valueNow, 10) >= septicThreshold
  ) {
    reachedThreshold = !reachedThreshold;
  } else if (
    sensorType === SensorGroup.water &&
    parseInt(valueNow, 10) <= waterThreshold
  ) {
    reachedThreshold = !reachedThreshold;
  } else if (
    (sensorType === SensorGroup.batteryVoltage ||
      sensorType === SensorGroup.batteryChargerVoltage) &&
    parseInt(valueNow, 10) <= batteryVoltageTreshold
  ) {
    reachedThreshold = !reachedThreshold;
  }
  return reachedThreshold;
};

const preferedTimeFormat = 'dd.MM.yyyy HH:mm';

const convertToLocalTime = (zuluTime: string): string => {
  return format(new Date(zuluTime), preferedTimeFormat);
};

const hasOldTimestamp = (zuluTime: string): boolean => {
  const oldThreshold = 1000 * 60 * 60 * 2; // 2 hours
  return new Date().getTime() - new Date(zuluTime).getTime() > oldThreshold;
};

export const hasValidSensorGroup = (sensorData: Signal): boolean => {
  return sensorData.group in SensorGroup;
};

function toSensorGroup(group: string): SensorGroup {
  return SensorGroup[group as keyof typeof SensorGroup];
}

const formatSensorValue = (sensor: Sensor, sensorData: Signal): string => {
  const addWhiteSpace = (input: string, input2: string): string => {
    return `${input}\u00a0${input2}`;
  };

  switch (sensor.group) {
    case SensorGroup.indoorTemperature:
    case SensorGroup.outdoorTemperature:
      return addWhiteSpace(
        parseFloat(sensorData.value).toFixed(1).replace('.', ','),
        sensorData.unit,
      );
    case SensorGroup.septic:
    case SensorGroup.water:
      return addWhiteSpace(
        parseFloat(sensorData.value).toFixed(0).replace('.', ','),
        sensorData.unit,
      );
    default:
      return addWhiteSpace(sensorData.value, sensorData.unit);
  }
};

export const processSensorData = (sensorData: Signal): ExpandedSignal => {
  const sensor: Sensor = {
    group: toSensorGroup(sensorData.group),
    carriage: sensorData.vehicleType,
  };
  const value = formatSensorValue(sensor, sensorData);
  const timestamp = convertToLocalTime(sensorData.timestamp);
  const old = hasOldTimestamp(sensorData.timestamp);
  const alarm = onSensorThreshold(sensorData.value, sensor.group);
  return {
    sensor,
    value,
    timestamp,
    old,
    isPastTreshold: alarm,
  };
};

export const processSensors = (sensorData: SensorData): VehicleData => {
  const sensors = sensorData.signals.filter(hasValidSensorGroup);
  const processedSensors = sensors.map(processSensorData);

  return {
    vehicleSetId: sensorData.vehicleSetId,
    processedSensors,
  };
};

export const sensorGroupRelation = [
  [SensorGroup.water, SensorGroup.septic],
  [SensorGroup.indoorTemperature],
  [SensorGroup.outdoorTemperature],
  [SensorGroup.batteryChargerVoltage],
  [SensorGroup.batteryVoltage],
];
