/**
 * External dependencies
 */
import React, { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * Internal dependencies
 */
import { FlexItem, FlexContainer } from 'shared/components/Layout/Flex/Flex';
import { PrimaryButtonSmall } from '../Button/PrimaryButton/PrimaryButton';

type BannerProps = {
  show: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: ReactNode;
};

export const BannerItem = styled(FlexItem)`
  background-color: ${({ theme }): string => theme.bannerBackground};
  color: ${({ theme }): string => theme.bannerTextColor};
  height: fit-content;
  text-align: center;
  padding: 20px 0;
  position: relative;
`;

export const BannerContainer = styled(FlexContainer)`
  position: fixed;
  bottom: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0;
  width: 100%;
  z-index: 50;
`;

const CloseBannerButton = styled.button`
  border: none;
  font-size: 14px;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  color: ${({ theme }): string => theme.bannerTextColor};

  :hover {
    cursor: pointer;
  }
`;

const Banner: React.FC<BannerProps> = (props: BannerProps) => {
  const { show, children, onClick, onClose } = props;

  if (!show) return null;
  return (
    <BannerContainer>
      <BannerItem
        data-testid='banner-item'
        onClick={(): void => {
          onClick?.();
        }}
      >
        <PrimaryButtonSmall>{children}</PrimaryButtonSmall>
        <CloseBannerButton
          data-testid='banner-close-button'
          onClick={(event): void => {
            event.stopPropagation();
            onClose();
          }}
        >
          X
        </CloseBannerButton>
      </BannerItem>
    </BannerContainer>
  );
};

export default Banner;
