import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'shared/styles/texts';
import RecentSearchButtonGroup from 'shared/components/RecentSearchButtons/RecentSearchButtons';

interface RecentSearchesProps {
  triggerSearch: (value: string) => void;
  recentSearches: string[];
}

export const RecentSearches: React.FC<RecentSearchesProps> = ({
  triggerSearch,
  recentSearches,
}: RecentSearchesProps) => {
  return (
    <>
      <Text>
        <FormattedMessage
          id='search.recentSearches'
          defaultMessage='Nylige søk'
        />
        :
      </Text>
      <RecentSearchButtonGroup
        clickFunction={(val) => {
          triggerSearch(val);
        }}
        recentSearches={recentSearches}
      />
    </>
  );
};
