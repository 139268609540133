import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchSVG } from './SearchIcon.svg';

const InputContainer = styled.div`
  display: flex;
  padding: 10px;
  height: 54px;
  border-radius: 6px;
  background-color: ${({ theme }): string =>
    theme.VyInputInputContainerBackground};
  align-items: center;
  border: 1px solid
    ${({ theme }): string => theme.VyInputInputContainerBorderColor};

  & svg {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  display: flex;
  border-radius: 6px;
  font-size: 16px;
  box-sizing: border-box;
  border: none;
  outline: none;
  flex: 6;

  ::placeholder {
    color: ${({ theme }): string => theme.VyInputStyledInputTextColor};
    font-size: 16px;
  }
`;

type InputTypes = 'Search' | 'Plain';

type InputProps = {
  placeholder?: string;
  type: InputTypes;
  value: string;
  onChange: (value: string) => void;
  onEnter?: (value: string) => void;
};

export const InputField = ({
  placeholder,
  type,
  value,
  onChange,
  onEnter,
}: InputProps) => {
  return (
    <InputContainer>
      <StyledInput
        data-testid='inputField'
        onKeyDown={(event) =>
          event.key === 'Enter' && onEnter && onEnter(value)
        }
        placeholder={placeholder}
        value={value}
        onChange={(e): void => {
          onChange(e.target.value);
        }}
      />
      {type === 'Search' && (
        <SearchSVG onClick={() => onEnter && onEnter(value)} />
      )}
    </InputContainer>
  );
};
