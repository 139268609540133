import { FilterId, FilterStrings } from 'api/apiDataTypes';

export type FiltersTypes = {
  id: FilterId;
  label: string;
};

export const sensorAlarms: FiltersTypes[] = [
  { id: 'water', label: 'sensorAlarms.waterFilterLabel' },
  { id: 'septic', label: 'sensorAlarms.septicFilterLabel' },
  { id: 'batteryVoltage', label: 'sensorAlarms.batteryVoltageFilterLabel' },
  {
    id: 'batteryChargerVoltage',
    label: 'sensorAlarms.batteryChargerVoltageFilterLabel',
  },
];

export const mapAlarmStringsToFilterIds = (alarmString: string): FilterId[] =>
  alarmString
    .split(',')
    .map((a) => a as FilterId)
    .filter((a) => FilterStrings.includes(a));
