import React, { ReactNode, createContext, useContext, useState } from 'react';
import styled from 'styled-components';

interface BackdropContextValue {
  showBackdrop: () => void;
  hideBackdrop: () => void;
}

interface Props {
  children: ReactNode[];
}

const Backdrop = styled.div`
  @media (max-width: ${({ theme }): string => theme.breakpointMobile}) {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 15;
    overflow: hidden;
    opacity: 0.8;
    background-color: ${({ theme }): string => theme.backdropBackground};
  }
`;

export const createBackdropContext = () => {
  const BackdropContext = createContext<BackdropContextValue>({
    showBackdrop: () => {},
    hideBackdrop: () => {},
  });

  const BackdropProvider: React.FC<Props> = ({ children }) => {
    const [backdropVisible, setBackdropVisible] = useState(false);

    const showBackdrop = () => {
      setBackdropVisible(true);
      if (window.screen.width < 576) {
        document.body.classList.add('no-scroll');
      }
    };

    const hideBackdrop = () => {
      setBackdropVisible(false);

      if (window.screen.width < 576) {
        if (document.body.classList.contains('no-scroll')) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    return (
      <BackdropContext.Provider value={{ showBackdrop, hideBackdrop }}>
        {backdropVisible && <Backdrop />}
        {children}
      </BackdropContext.Provider>
    );
  };

  const useBackdropContext = () => {
    return useContext(BackdropContext);
  };

  return { BackdropProvider, useBackdropContext };
};
