import React from 'react';
import { LocationActivity } from 'api/apiDataTypes';
import IconAndTextItem from 'shared/components/IconAndTextItem/IconAndTextItem';
import { formatDateString } from 'shared/utils/dateUtils';
import { getActivityIconMapping } from './utils';

interface PlannedActivitiesProps {
  locationActivity: LocationActivity;
}

const PlannedActivities: React.FC<PlannedActivitiesProps> = (
  props: PlannedActivitiesProps,
) => {
  const { locationActivity } = props;

  return (
    <div style={{ margin: '0', whiteSpace: 'normal' }}>
      {locationActivity?.activities?.map((activity, index) => {
        return (
          <IconAndTextItem
            icon={getActivityIconMapping(activity.activityType)}
            key={index}
            margin='15px 0'
          >
            {`${activity.activityType}: ${formatDateString(
              activity.startTime,
            )} - ${formatDateString(activity.endTime)}`}
          </IconAndTextItem>
        );
      })}
    </div>
  );
};

export default PlannedActivities;
