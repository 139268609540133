import React, { ReactNode, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { getLocale, getLocalizedMessages } from '../utils/languageUtils';
import { setStoredLocale } from '../utils/localStorageUtils';

type IntlContextValue = {
  setLocale: (locale: string) => void;
};

const initialValue: IntlContextValue = {
  setLocale: () => {},
};

const IntlContextWrapper = React.createContext(initialValue);

type Props = {
  children: ReactNode;
};

const IntlProviderWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const [localeState, setLocaleState] = useState(getLocale());

  const setLocale = (newLocale: string): void => {
    setLocaleState(newLocale);
    setStoredLocale(newLocale);
  };

  return (
    <IntlContextWrapper.Provider value={{ setLocale }}>
      <IntlProvider
        key={localeState}
        locale={localeState}
        messages={getLocalizedMessages()}
        onError={(): void => {}}
      >
        {children}
      </IntlProvider>
    </IntlContextWrapper.Provider>
  );
};

const useIntlWrapper = (): IntlContextValue => {
  const context = React.useContext(IntlContextWrapper);
  if (context === undefined) {
    throw new Error(`useIntlWrapper must be used within a IntlContextWrapper`);
  }
  return context;
};

export { IntlProviderWrapper, useIntlWrapper };
