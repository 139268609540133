import React, { useContext, useEffect, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { FlexContainer, FlexItem } from 'shared/components/Layout/Flex/Flex';
import AlertContext from 'shared/context/AlertContext';
import { Text } from 'shared/styles/texts';
import { AppIcon } from 'shared/components/Icons/Icons';
import { ExpandedSignal } from '../../../shared/utils/sensorUtils';

interface SignalItemProps {
  signal: ExpandedSignal;
}

/**
 * The FlexItems take in maxWidth so that multiple SignalItems are vertically aligned.
 */
const SignalItem: React.FC<SignalItemProps> = ({ signal }) => {
  const notifyAlert = useContext(AlertContext);

  useEffect(() => {
    if (signal.isPastTreshold) {
      notifyAlert();
    }
  });

  const textStyle: CSSProperties = signal.isPastTreshold
    ? { color: 'red', fontWeight: 'bold' }
    : {};
  return (
    <FlexContainer data-testid='sensor-detail' direction='row'>
      <FlexItem margin='0' maxWidth={`${20}%`}>
        <Text data-testid='sensor' style={textStyle}>
          {signal.sensor.carriage}
        </Text>
      </FlexItem>
      <FlexItem margin='0' maxWidth={`${25}%`}>
        <Text data-testid='value' style={textStyle}>
          {signal.value}
        </Text>
      </FlexItem>
      <FlexItem margin='0' maxWidth={`${55}%`}>
        <FlexContainer direction='row'>
          <Text data-testid='timestamp' style={textStyle}>
            {signal.timestamp}
          </Text>
          {signal.old && (
            <>
              <div data-tip data-for='history'>
                <AppIcon
                  type='history'
                  marginLeft='7.5px'
                  marginRight='7.5px'
                />
              </div>
              <ReactTooltip id='history' place='top' effect='solid'>
                <FormattedMessage
                  id='sensorReadings.oldData'
                  defaultMessage='Denne verdien er eldre enn 2 timer'
                />
              </ReactTooltip>
            </>
          )}
        </FlexContainer>
      </FlexItem>
    </FlexContainer>
  );
};

export default SignalItem;
