import { hasAccessToken } from 'auth/loginFlowTypes';
import useLoginFlow from 'auth/useLoginFlow';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  getIsInstallBannerDismissed,
  setDismissedInstallBanner,
} from '../../../utils/localStorageUtils';
import Banner from '../Banner';

const BEFORE_INSTALL_PROMPT = 'beforeinstallprompt';

const InstallBanner: React.FC = () => {
  const [deferredPrompt, setDeferredPromptState] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [showBannerState, setShowBannerState] = useState(false);
  const auth = hasAccessToken(useLoginFlow().state);

  const setDeferredPrompt = (e: BeforeInstallPromptEvent | null): void => {
    setDeferredPromptState(e);
    if (e !== null) setShowBannerState(!getIsInstallBannerDismissed() && auth);
    else setShowBannerState(false);
  };

  window.addEventListener(BEFORE_INSTALL_PROMPT, (e) => {
    e.preventDefault();
    setDeferredPrompt(e as BeforeInstallPromptEvent);
  });

  const promptUser = (): void => {
    if (deferredPrompt) {
      deferredPrompt.prompt().then((userChoice: any) => {
        if (userChoice.outcome === 'accepted') setDeferredPrompt(null);
      });
    }
  };

  const dismissInstall = (): void => {
    setDismissedInstallBanner(true);
    setDeferredPrompt(null);
  };

  return (
    <Banner
      show={showBannerState}
      onClick={promptUser}
      onClose={dismissInstall}
    >
      <FormattedMessage
        id='banner.addToHome'
        defaultMessage='Legg til på hjemskjerm'
      />
    </Banner>
  );
};

export default InstallBanner;
