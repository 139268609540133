import * as colors from '../../colors';

const shared = {
  backdropBackground: colors.darkGrey,
  primaryBackground: colors.backgroundWhite,
  secondaryBackground: colors.blueGrey10,
  defaultCardBorderColor: colors.lightBlue40,
  errorColor: colors.errorRed,
};

export default shared;
