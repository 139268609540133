import * as colors from '../../colors';

const base = {
  body: colors.backgroundWhite,
  resultBody: colors.lightBlue20,
  title: colors.backgroundWhite,
  headerColor: colors.backgroundWhite,
  textColor: colors.darkGrey,
  subTextColor: colors.blueGrey80,
};

export default base;
