import React from 'react';
import styled from 'styled-components';
import { AdditionalButtonExtraSmall } from '../Button/AdditionalButton/AdditionalButton';

type ButtonGroupProps = {
  clickFunction: (a: string) => void;
  recentSearches: string[];
};

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  background-color: ${({ theme }): string => theme.detailItemBackground};

  & > * {
    margin-right: 6px;
    margin-bottom: 6px;
  }
`;

const RecentSearchButtonGroup: React.FC<ButtonGroupProps> = (
  props: ButtonGroupProps,
) => {
  const { recentSearches, clickFunction } = props;

  return (
    <ButtonContainer>
      {recentSearches?.map((search: string, index) => (
        <AdditionalButtonExtraSmall
          key={search + index}
          data-testid='recent-search-button'
          onClick={(): void => clickFunction(search)}
        >
          {search?.toUpperCase()}
        </AdditionalButtonExtraSmall>
      ))}
    </ButtonContainer>
  );
};

export default RecentSearchButtonGroup;
