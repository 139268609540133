/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Internal dependencies
 */
import { FilterId } from 'api/apiDataTypes';
import { FilterButton } from 'shared/components/FilterButton/FilterButton';
import Checkbox from 'shared/components/Checkbox/Checkbox';
import { sensorAlarms } from 'shared/utils/sensorAlarmsUtils';
import { SensorAlarmFilterProps } from './SensorAlarmFilterTypes';
import { SensorAlarmFilters } from './SensorAlarmFilterStyles';

export const SensorAlarmFilter: React.FC<SensorAlarmFilterProps> = ({
  alarmFilters,
  setAlarmFilters,
}) => {
  const [checkedFilters, setCheckedFilters] =
    useState<FilterId[]>(alarmFilters);

  useEffect(() => {
    if (alarmFilters.join(',') !== checkedFilters.join(',')) {
      setCheckedFilters(alarmFilters);
    }
  }, [alarmFilters]);

  const handleCheckedFilters = (id: FilterId, checked: boolean): void => {
    if (checked) {
      setCheckedFilters(
        checkedFilters.filter((sensorGroupId) => sensorGroupId !== id),
      );
    } else {
      setCheckedFilters([...checkedFilters, id]);
    }
  };

  const updateFilters = () => {
    setAlarmFilters(checkedFilters);
  };

  return (
    <FilterButton
      selectedFilters={alarmFilters}
      onSelect={updateFilters}
      buttonActiveTextId='sensorAlarmFilter.buttonTextActive'
      buttonInactiveTextId='sensorAlarmFilter.buttonTextDefault'
      titleTextId='sensorAlarmFilter.info'
    >
      <SensorAlarmFilters>
        {sensorAlarms.map((sensorAlarm) => (
          <Checkbox
            key={sensorAlarm.id}
            checked={checkedFilters.includes(sensorAlarm.id)}
            id={sensorAlarm.id}
            handleChecked={handleCheckedFilters}
          >
            <FormattedMessage
              id={sensorAlarm.label}
              defaultMessage={sensorAlarm.id}
            />
          </Checkbox>
        ))}
      </SensorAlarmFilters>
    </FilterButton>
  );
};
