import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import React from 'react';
import IconAndTextItem from 'shared/components/IconAndTextItem/IconAndTextItem';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import styled from 'styled-components';
import { FlexContainer } from 'shared/components/Layout/Flex/Flex';
import { Gps } from 'api/apiDataTypes';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

interface GPSProps {
  gps: Gps;
  vehicleSetId: string;
}

const Container = styled.div`
  border: 1px solid #dce4ef;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const GPS: React.FC<GPSProps> = (props) => {
  const { gps, vehicleSetId } = props;
  const { latitude, longitude, timestamp } = gps;

  function createURL(lat: number, lon: number): string {
    return `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}#map=14/${lat}/${lon}&layers=T`;
  }

  const formattedDate = format(new Date(timestamp), 'HH:mm');
  const streetMapURL = createURL(latitude, longitude);

  return (
    <Container>
      <MapContainer
        style={{ height: '300px', width: '100%' }}
        center={[latitude, longitude]}
        zoom={16}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker icon={DefaultIcon} position={[latitude, longitude]}>
          <Popup>
            <FormattedMessage
              id='gps.vehicle'
              values={{
                id: vehicleSetId,
              }}
              defaultMessage='Vehicle xx-yy'
            />{' '}
          </Popup>
        </Marker>
      </MapContainer>
      <IconAndTextItem icon='position'>
        <FlexContainer
          width='100%'
          direction='row'
          margin='0 1rem 0 0'
          alignItems='center'
          justifyContent='space-between'
        >
          <FormattedMessage
            id='gps.lastSeen'
            values={{
              time: formattedDate,
            }}
            defaultMessage='Last seen hh:mm'
          />
          <a href={streetMapURL} target='_blank' rel='noopener noreferrer'>
            <FormattedMessage id='gps.showInMap' defaultMessage='Show in map' />
          </a>
        </FlexContainer>
      </IconAndTextItem>
    </Container>
  );
};

export default GPS;
