import { useIntl } from 'react-intl';
import { sensorAlarms } from 'shared/utils/sensorAlarmsUtils';

export const getTranslatedAlarmString = (urlParam: string) => {
  const intl = useIntl();
  const alarm = sensorAlarms.find((alarm) => alarm.id === urlParam);

  if (alarm) {
    return intl.formatMessage({
      id: alarm.label,
      defaultMessage: alarm.id,
    });
  }

  return null;
};
