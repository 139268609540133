/**
 * Design system colors
 * https://www.figma.com/file/mNzRMrE8U96Q7gCmGwt5iC/Farger%2C-typografi%2C-stiler-og-effekter?node-id=119%3A8017
 */

// Primary colors.
export const actionGreen = '#00866E';
export const darkGreenContrast = '#00453E';
export const backgroundWhite = '#FFFFFF';
export const darkGrey = '#2B2B2C';
export const lightBlueContrast = '#4178BE';
export const darkBlueContrast = '#003A70';
export const errorRed = '#ED0000';
export const errorRed20 = '#FBCCCC';

// Bluegrey colors.
export const blueGrey = '#383E42';
export const blueGrey80 = '#606568';
export const blueGrey60 = '#888B8E';
export const blueGrey40 = '#AFB2B3';
export const blueGrey20 = '#D7D8D9';
export const blueGrey10 = '#EBEBEC';

// Green colors.
export const darkGreen = '#00685E';
export const darkGreen80 = '#33867E';

export const lightGreen80 = '#33AA95';
export const lightGreen20 = '#CCEAE4';
export const lightGreen10 = '#E5F4F1';
export const lightGreen = '#00957A';

// Blue colors.
export const lightBlue = '#A7BCD6';
export const lightBlue60 = '#CAD7E6';
export const lightBlue40 = '#DCE4EF';
export const lightBlue20 = '#EDF1F7';

// Orange colors.
export const orange140 = '#994E00';

// Yellow colors.
export const yellow = '#FAE053';
export const yellow40 = '#FDF3BA';

// Pink colors.
export const pink120 = '#CC003D';
