import styled from 'styled-components';

export const OuterContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

export const FilterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  padding: 18px 18px 60px;
  background-color: ${({ theme }): string => theme.sensorAlarmFilterBackground};
  width: 100%;
  font-size: 14px;

  @media (min-width: ${({ theme }): string => theme.breakpointMobile}) {
    width: 400px;
    position: absolute;
    z-index: 20;
    top: 50px;
    bottom: initial;
    left: initial;
    font-size: 16px;
    padding: 18px;
  }
`;

export const SensorAlarmFilters = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  & > * {
    padding: 0 10px 18px 0;
    width: 50%;
  }
`;

export const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  & h3 {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
`;

export const CloseIcon = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
