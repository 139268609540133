/**
 * External dependencies
 */
import styled, { css } from 'styled-components';

type Props = {
  dynamic?: boolean;
};

const button = styled.button<Props>`
  border-radius: 200px;
  border: 0;
  margin: 0;

  ${({ dynamic }) =>
    dynamic &&
    css`
      width: 100%;
    `};

  :focus-visible {
    border: 2px solid;
    border-radius: 200px;
    outline: none;
  }

  :hover:enabled {
    cursor: pointer;
  }
`;

export default button;
