import React, { ReactNode } from 'react';
import { FlexContainer, FlexItem } from 'shared/components/Layout/Flex/Flex';

interface Props {
  margin?: string;
  children?: ReactNode;
  rowDirection?: string;
}

/**
 * This component only needs an icon and wrapped around text to make it univeral across the app.
 */
const TextItems: React.FC<Props> = (props: Props) => {
  const { margin, children, rowDirection } = props;
  return (
    <FlexContainer margin={margin} direction={rowDirection}>
      <FlexItem margin='0'>{children}</FlexItem>
    </FlexContainer>
  );
};

TextItems.defaultProps = {
  rowDirection: 'row',
  margin: '1rem',
};

export default TextItems;
