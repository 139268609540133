import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import no from 'date-fns/locale/nb';
import sv from 'date-fns/locale/sv';
import en from 'date-fns/locale/en-GB';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Text } from '../../../styles/texts';
import { getLocale } from '../../../utils/languageUtils';

registerLocale('no', no);
registerLocale('sv', sv);
registerLocale('en', en);

const DatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DateTextWrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px;
  border-radius: 6px;
  height: 53px;
  cursor: pointer;
  min-width: 190px;

  & > *:first-child {
    border-right-style: solid;
    border-right-width: 1px;
    flex: 5;
    height: 53px;
    align-items: center;
    padding-left: 8px;
  }

  & > *:last-child {
    flex: 3;
    align-items: center;
    padding-left: 5px;
    height: 53px;
  }
`;

const LabelTextWrapper = styled.div`
  & > *:first-child {
    font-size: 14px;
    height: 20px;
    margin-top: 4px;
  }
`;

interface VyDatePickerProps {
  selected: Date | null;
  onChange: (date: Date) => void;
  label?: string;
}

const minDate = new Date();
const maxDate = new Date();
minDate.setDate(minDate.getDate() - 1);
maxDate.setDate(maxDate.getDate() + 10);

export const VYClickDatePicker = ({
  selected,
  onChange,
  label,
}: VyDatePickerProps) => {
  let locale: Locale = en;
  const currentLocale = getLocale();

  if (currentLocale === 'nb') {
    locale = no;
  }
  if (currentLocale === 'se') {
    locale = sv;
  }

  return (
    <DatePickerContainer>
      <DatePicker
        locale={locale}
        selected={selected}
        onChange={onChange}
        placeholderText={label}
        dateFormat='dd.MM.yy H:mm'
        showTimeSelect
        timeIntervals={60}
        minDate={minDate}
        maxDate={maxDate}
        customInput={
          <DateTextWrapper>
            <LabelTextWrapper>
              <Text>
                <FormattedMessage id='search.date' defaultMessage='Dato' />
              </Text>
              <Text>
                {selected && format(selected, 'dd. MMMM', { locale })}
              </Text>
            </LabelTextWrapper>
            <LabelTextWrapper>
              <Text>
                <FormattedMessage id='search.time' defaultMessage='tid' />
              </Text>
              <Text>{selected && format(selected, 'H:mm', { locale })}</Text>
            </LabelTextWrapper>
          </DateTextWrapper>
        }
        showPopperArrow={false}
      />
    </DatePickerContainer>
  );
};
