import axios from 'axios';
import requestConfig from './api';
import { SensorData } from './apiDataTypes';
import apiUrl from './apiUrl';

async function getSensorDataFromVehicleSetId(params: {
  vehicleSetId: string;
}): Promise<SensorData> {
  const { vehicleSetId } = params;

  const config = requestConfig();

  return axios
    .get(`${apiUrl()}/vehicle/${encodeURIComponent(vehicleSetId)}`, config)
    .then((res) => res.data);
}
export default getSensorDataFromVehicleSetId;
