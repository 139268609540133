import axios from 'axios';
import { getFormattedDate } from 'shared/utils/dateUtils';
import requestConfig from './api';
import { SearchResult } from './apiDataTypes';
import apiUrl from './apiUrl';

type SearchDataParams = {
  query: string;
  selectedDate: Date;
  setTypes: string[];
};

export const getSearchQueryData = ({
  query,
  selectedDate,
  setTypes,
}: SearchDataParams): Promise<SearchResult> =>
  axios
    .get(`${apiUrl()}/search/`, {
      ...requestConfig(),
      params: {
        query,
        date: getFormattedDate(selectedDate),
        setTypes: setTypes.length ? setTypes.join(',') : undefined,
      },
    })
    .then((res) => res.data);
