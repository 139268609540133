import axios from 'axios';
import requestConfig from './api';
import apiUrl from './apiUrl';
import { getFormattedDate } from '../shared/utils/dateUtils';
import { Train } from './apiDataTypes';

type StatusDataParams = {
  trainId: string;
  date: Date;
};

const getTrainData = ({ trainId, date }: StatusDataParams): Promise<Train> =>
  axios
    .get<Train>(`${apiUrl()}/train`, {
      ...requestConfig(),
      params: {
        trainId,
        date: getFormattedDate(date),
      },
    })
    .then((res) => res.data);

export default getTrainData;
