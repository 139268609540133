import { addMinutes, format, isToday, parseISO } from 'date-fns';

export const formatDateString = (
  dateString: string,
  todayFormat = 'HH:mm',
  otherFormat = 'd/M\u00a0-\u00a0HH:mm',
  failoverText = '',
): string => {
  if (dateString === null) return '';
  try {
    const date = parseISO(dateString);
    if (isToday(date)) return format(date, todayFormat);
    return format(date, otherFormat);
  } catch (e) {
    return failoverText;
  }
};

export const getFormattedDate = (date: Date) =>
  formatDateString(
    date?.toISOString() || new Date().toISOString(),
    'yyyy-MM-dd',
    'yyyy-MM-dd',
    undefined,
  );

export const timeWithDelay = (
  dateString: string,
  formatString: string,
  delay = 0,
  failoverText = '',
): string => {
  try {
    return format(addMinutes(parseISO(dateString), delay), formatString);
  } catch (e) {
    return failoverText;
  }
};

export const getTimestampInSeconds = (timePeriodInSeconds: number) => {
  return Math.floor(Date.now() / 1000) - timePeriodInSeconds;
};
