/* eslint-disable react/destructuring-assignment */
import React, { ReactNode } from 'react';

export interface NotifyDataLoading {
  setLoading: React.Dispatch<any>;
  setError: React.Dispatch<any>;
  setStatusCode: React.Dispatch<any>;
  setData: React.Dispatch<any>;
}

interface Props {
  children?: ReactNode;
}

const emptyDictionary = (): { [id: string]: NotifyDataLoading[] } => {
  return {};
};

const DataLoadingContext = React.createContext({
  loading: emptyDictionary(),
});

export const DefaultDataLoadingContextProvider: React.FC<Props> = ({
  children,
}) => {
  const defaultValue = {
    loading: emptyDictionary(),
  };
  return (
    <DataLoadingContext.Provider value={defaultValue}>
      {children}
    </DataLoadingContext.Provider>
  );
};

export default DataLoadingContext;
