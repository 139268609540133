import React from 'react';
import { ExpandedSignal } from '../../../shared/utils/sensorUtils';
import SignalItem from '../SignalItem/SignalItem';
import TextItems from '../../../shared/components/TextItems/TextItems';

type SignalGroupProps = {
  icon: string;
  sensors: ExpandedSignal[];
};

const SignalGroup: React.FC<SignalGroupProps> = (props: SignalGroupProps) => {
  const { sensors, icon } = props;

  if (sensors.length > 0) {
    return (
      <TextItems data-testid='iconAndText'>
        {sensors.map((signal) => {
          return (
            <SignalItem
              signal={signal}
              key={`${signal.sensor.group}, ${signal.sensor.carriage}`}
            />
          );
        })}
      </TextItems>
    );
  }
  return null;
};

export default SignalGroup;
