type ActivityMapping = {
  [id: string]: string;
};

const mapping: ActivityMapping = {
  cleaning: 'cleaning',
  MAINTENANCE: 'hammerWrench',
  refueling: 'diesel',
  STAND_BY: 'hourglass',
  undefined: 'question',
};

export const getActivityIconMapping = (
  activity: string | undefined,
): string => {
  const type = activity || 'undefined';
  return mapping[type];
};
