import { GroupDto } from 'features/SearchContent/ActivityAndVehicleSearchResults/Graph/GraphTypes';

export type LocationActivity = {
  location: string;
  locationName: string | null;
  vehicleSetId: string;

  // Arrival Train info
  origin: string;
  originName: string | null;
  arrivalTrainId: string;
  arrivalNominalDate: string;
  // Arrival times
  actualArrivalTime: string;
  estimatedArrivalTime: string;
  scheduledArrivalTime: string;
  arrivalDelay: number;
  // Arrival track
  actualArrivalTrack: string;
  estimatedArrivalTrack: string;
  scheduledArrivalTrack: string;

  // Departure Train info
  destination: string;
  destinationName: string | null;
  departureTrainId: string;
  departureNominalDate: string;
  // Departure times
  actualDepartureTime: string;
  estimatedDepartureTime: string;
  scheduledDepartureTime: string;
  departureDelay: number;
  // Departure track
  actualDepartureTrack: string;
  estimatedDepartureTrack: string;
  scheduledDepartureTrack: string;

  coupledPosition: number | null;

  activities: Activity[];

  // Alarms
  activeSensorAlarms: FilterId[];
};

export type VehicleSet = {
  vehicleSetId: string;
  hasActivities: string;
};

export type SearchResult = {
  activities: LocationActivity[];
  vehicleSets: VehicleSet[];
};

export const FilterStrings = [
  'water',
  'septic',
  'batteryVoltage',
  'batteryChargerVoltage',
] as const;

export type FilterId = typeof FilterStrings[number];

export type Activity = {
  activityType: string;
  startTime: string;
  endTime: string;
};

export type Gps = {
  latitude: number;
  longitude: number;
  speedKnots: number;
  timestamp: string;
};

export type SensorData = {
  vehicleSetId: string;
  signals: Signal[];
  gps?: Gps;
};

export type HistoryData = {
  vehicleSetId: string;
  groups: GroupDto[];
};

export type Signal = {
  signalId: string;
  timestamp: string;
  vehicleType: string;
  group: string;
  unit: string;
  value: string;
};

export enum SensorGroup {
  septic,
  water,
  indoorTemperature,
  outdoorTemperature,
  batteryChargerVoltage,
  batteryVoltage,
  other,
  all,
}

export type Train = {
  statusMessages: StatusMessage[];
};

export type StatusMessage = {
  text: StatusText;
  timestamp: Date;
};

export type StatusText = {
  nob: string;
};
