import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'shared/styles/texts';
import {
  Container,
  SliderInput,
  SliderLabel,
  SliderSpan,
} from './SliderSwitchStyle';

type SliderSwitchProps = {
  isChecked: boolean;
  onChange: Function;
  testId?: string;
  title: string;
  checkedTextId?: string;
  uncheckedTextId?: string;
  checkedDefaultText?: string;
  uncheckedDefaultText?: string;
};

const SliderSwitch = ({
  testId,
  title,
  isChecked,
  onChange,
  checkedTextId,
  uncheckedTextId,
  checkedDefaultText,
  uncheckedDefaultText,
}: SliderSwitchProps) => (
  <Container>
    <SliderLabel title={title}>
      <SliderInput
        type='checkbox'
        checked={isChecked}
        onChange={(event) => onChange(event)}
        id={testId}
      />
      <SliderSpan />
    </SliderLabel>
    {checkedTextId && uncheckedTextId && (
      <Text>
        <FormattedMessage
          id={isChecked ? checkedTextId : uncheckedTextId}
          defaultMessage={isChecked ? checkedDefaultText : uncheckedDefaultText}
        />
      </Text>
    )}
  </Container>
);

export default SliderSwitch;
