import styled, { css } from 'styled-components';

const TextCSS = css`
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  word-break: break-word;
  margin: 0;
  overflow: hidden;
`;

type TextProps = {
  bold?: boolean;
};

const Text = styled.p`
  ${TextCSS};
  color: ${({ theme }) => theme.textColor};
  font-family: ${(props: TextProps) => (props.bold ? 'VySans-Bold' : 'VySans')};
`;

const SubText = styled.p`
  ${TextCSS};
  color: ${({ theme }) => theme.subTextColor};
  font-family: ${(props: TextProps) => (props.bold ? 'VySans-Bold' : 'VySans')};
`;

export { Text, SubText };
