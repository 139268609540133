import styled from 'styled-components';
import { blueGrey60 } from 'shared/styles/colors';

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -18px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  height: 120px;
  overflow: auto;
  border-bottom: 1px solid ${blueGrey60};
  padding: 6px 0 0 6px;

  & > * {
    padding: 0 10px 18px 0;
    width: 50%;
  }
`;
