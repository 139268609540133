/**
 * External dependencies
 */
import styled, { css } from 'styled-components';
import button from '../button';

const primaryButton = styled(button)`
  background-color: ${({ theme }): string => theme.btnPrimaryDefaultBackground};
  color: ${({ theme }): string => theme.btnPrimaryDefaultTextColor};
  font-weight: 700;

  :focus-visible {
    border-style: solid;
    border-color: ${({ theme }): string => theme.btnPrimaryFocusTextColor};
  }

  :hover:enabled {
    background-color: ${({ theme }): string => theme.btnPrimaryHoverBackground};
  }

  :active {
    background-color: ${({ theme }): string =>
      theme.btnPrimaryActiveBackground};
  }

  :disabled {
    background-color: ${({ theme }): string =>
      theme.btnPrimaryDisabledBackground};
    color: ${({ theme }): string => theme.btnPrimaryDisabledTextColor};
  }
`;

export const PrimaryButtonLarge = styled(primaryButton)`
  padding: 15px 18px;
  font-size: 18px;
  line-height: 24px;

  :focus-visible {
    border-width: 2px;
    box-shadow: 0 0 0 4px
      ${({ theme }): string => theme.btnPrimaryFocusBackground};
    margin: 4px;
    padding: 9px 12px;

    ${(props) =>
      props.dynamic &&
      css`
        width: calc(100% - 8px);
      `};
  }
`;

export const PrimaryButtonMedium = styled(primaryButton)`
  padding: 9px 18px;
  font-size: 18px;
  line-height: 24px;

  :focus-visible {
    border-width: 1px;
    box-shadow: 0 0 0 2px
      ${({ theme }): string => theme.btnPrimaryFocusBackground};
    margin: 2px;
    padding: 6px 14px;

    ${(props) =>
      props.dynamic &&
      css`
        width: calc(100% - 4px);
      `};
  }
`;

export const PrimaryButtonSmall = styled(primaryButton)`
  padding: 7px 18px;
  font-size: 16px;
  line-height: 22px;

  :focus-visible {
    border-width: 1px;
    box-shadow: 0 0 0 2px
      ${({ theme }): string => theme.btnPrimaryFocusBackground};
    margin: 2px;
    padding: 4px 15px;

    ${(props) =>
      props.dynamic &&
      css`
        width: calc(100% - 4px);
      `};
  }
`;
