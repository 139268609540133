import React from 'react';
import styled from 'styled-components';
import { backgroundWhite, lightBlue40 } from 'shared/styles/colors';
import SensorList from 'features/SensorList/SensorList';
import { VehicleData, sensorGroupRelation } from 'shared/utils/sensorUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${backgroundWhite};
  border: 1px solid ${lightBlue40};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px;
  margin-top: 6px;
`;

const HeaderButton = styled.button`
  width: fit-content;
  margin-bottom: 18px;
`;

interface AlarmEntryProps {
  vehicleData: VehicleData;
  triggerSearch: (value: string) => void;
}

const AlarmEntry: React.FC<AlarmEntryProps> = ({
  vehicleData,
  triggerSearch,
}: AlarmEntryProps) => {
  const { vehicleSetId, processedSensors } = vehicleData;
  const activeAlarms = processedSensors
    .filter((sensor) => sensor.isPastTreshold)
    .map((s) => s.sensor.group);
  const activeAlarmsWithRelated = sensorGroupRelation
    .filter((relation) =>
      relation.some((group) => activeAlarms.includes(group)),
    )
    .flat();

  return (
    <Container>
      <HeaderButton
        type='button'
        onClick={() => triggerSearch(vehicleData.vehicleSetId)}
      >
        <b>{vehicleSetId}</b>
      </HeaderButton>
      <SensorList
        vehicleSetId={vehicleSetId}
        processedSensors={processedSensors}
        activeFilters={activeAlarmsWithRelated}
        header
      />
    </Container>
  );
};

export default AlarmEntry;
