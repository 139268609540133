/* eslint-disable no-nested-ternary */

import jwt, { VerifyCallback, Algorithm } from 'jsonwebtoken';
import jwkToPem, { RSA } from 'jwk-to-pem';
import { hasLoginSettings } from './loginFlowTypes';
import { getState } from './storage';

export type Jwk = {
  alg: string;
  e: string;
  kid: string;
  kty: string;
  n: string;
  use: string;
};

export type Jwks = {
  keys: Jwk[];
};

export type ValidationConfig = {
  access_jwk: jwkToPem.RSA;
  id_jwk: jwkToPem.RSA;
  alg: jwt.Algorithm[];
  clientId: string;
  jwksIssuer: string;
};

const getValidationConfig = (): ValidationConfig => {
  const state = getState();

  if (!hasLoginSettings(state)) {
    throw new Error('Attempting to validate tokens without settings');
  }

  const { jwks, clientId, jwksIssuer } = state.settings;

  const idToken_E = jwks?.keys ? (jwks?.keys[0] ? jwks?.keys[0].e : 'e') : 'e';
  const idToken_N = jwks?.keys ? (jwks?.keys[0] ? jwks?.keys[0].n : 'n') : 'n';
  const accessToken_E = jwks?.keys
    ? jwks?.keys[1]
      ? jwks?.keys[1].e
      : 'e'
    : 'e';
  const accessToken_N = jwks?.keys
    ? jwks?.keys[1]
      ? jwks?.keys[1].n
      : 'n'
    : 'n';

  const alg = (jwks?.keys
    ? jwks?.keys[0]
      ? jwks?.keys[0].alg
      : 'RS256'
    : 'RS256') as unknown as Algorithm[];

  const accessJwk: RSA = {
    e: accessToken_E,
    kty: 'RSA',
    n: accessToken_N,
  };

  const idJwk: RSA = {
    e: idToken_E,
    kty: 'RSA',
    n: idToken_N,
  };

  return { access_jwk: accessJwk, id_jwk: idJwk, alg, clientId, jwksIssuer };
};

export const verifyAwsAccessToken = (
  token: string,
  callback: VerifyCallback,
): void => {
  const config = getValidationConfig();
  jwt.verify(
    token,
    jwkToPem(config.access_jwk),
    {
      algorithms: config.alg,
      issuer: config.jwksIssuer,
    },
    callback,
  );
};

export const verifyThatTokenIsStillValid = (token: string) =>
  new Promise<void>((resolve, reject) => {
    verifyAwsAccessToken(token, (error) => {
      if (!error) {
        resolve();
      } else {
        reject();
      }
    });
  });
