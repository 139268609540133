import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const SliderLabel = styled.label`
  position: relative;
  display: inline-block;
  height: 22px;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 15px;
  width: 42px;
`;

export const SliderInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SliderSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border: 2px solid
    ${({ theme }): string => theme.sliderSwitchHolderBorderColor};

  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    background-color: ${({ theme }): string =>
      theme.sliderSwitchCircleBackground};
  }

  ${SliderInput}:checked + &:before {
    background-color: ${({ theme }): string =>
      theme.sliderSwitchHolderCheckedCirleBackground};
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  ${SliderInput}:checked + & {
    border: 2px solid
      ${({ theme }): string => theme.sliderSwitchCheckedHolderBorderColor};
    background-color: ${({ theme }): string =>
      theme.sliderSwitchCheckedHolderBackground};
  }
`;
