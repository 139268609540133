const apiUrl = (): string => {
  // Use naive method to find API-url to avoid the need of separate builds for each environment
  if (process.env.NODE_ENV === 'development') {
    // return 'http://localhost:8080';
    return 'https://services.stage.rollingstock.vydev.io/driftstjenester-backend';
    // return `https://services.rollingstock.vydev.io/driftstjenester-backend`;
  }

  // Production:
  if (window.location.host === 'driftstjenester.vy.no') {
    return `https://services.rollingstock.vydev.io/driftstjenester-backend`;
  }

  return `https://services.${window.location.host
    .split('.')
    .splice(1)
    .join('.')}/driftstjenester-backend`;
};

export default apiUrl;
