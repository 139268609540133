import { FormattedMessage, useIntl } from 'react-intl';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useIntlWrapper } from 'shared/context/IntlContextWrapper';
import { AdditionalButtonExtraSmall } from '../Button/AdditionalButton/AdditionalButton';

type LanguageSelectorProps = {
  languageCode: string;
  buttonText?: string;
  style?: CSSProperties;
};
const LanguageButton: React.FC<LanguageSelectorProps> = (
  props: LanguageSelectorProps,
) => {
  const intl = useIntl();
  const { setLocale } = useIntlWrapper();
  const { languageCode, buttonText, style } = props;

  if (intl.locale === languageCode) return null;

  return (
    <AdditionalButtonExtraSmall
      style={style}
      onClick={(): void => setLocale(languageCode)}
    >
      {buttonText || languageCode.toUpperCase()}
    </AdditionalButtonExtraSmall>
  );
};

export const LanguageSelector: React.FC = () => {
  return (
    <ButtonRow>
      <LanguageButton languageCode='nb' buttonText='NO' />
      <LanguageButton languageCode='en' />
      <LanguageButton languageCode='se' />
    </ButtonRow>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 0;

  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const LanguageSelectorWithLabel: React.FC = () => {
  return (
    <Container>
      <FormattedMessage
        id='menu.changeLanguage'
        defaultMessage='Change language'
      />
      <ButtonRow>
        <LanguageSelector />
      </ButtonRow>
    </Container>
  );
};
