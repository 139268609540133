import React, { ReactNode, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { LocationActivity, StatusMessage, Train } from 'api/apiDataTypes';
import getTrainData from 'api/getTrainData';
import alert from 'shared/images/alert.svg';
import arrow from 'shared/images/arrow.svg';
import {
  BoldSpan,
  RotateArrow,
  StatusBody,
  StatusContainer,
  StatusHeader,
  StatusIcon,
  StatusText,
  StatusTitle,
} from './StatusMessageStyles';

// Components for the yellow status messages

const StatusMessages: React.FC<StatusProps> = ({
  locationActivity,
  selectedDate,
}) => {
  const statusDataParams = {
    trainId: locationActivity.arrivalTrainId
      ? locationActivity.arrivalTrainId
      : locationActivity.departureTrainId,
    date: selectedDate,
  };
  const statusData = useQuery<Train>(['statusData', statusDataParams], () =>
    getTrainData(statusDataParams),
  );
  const intl = useIntl();

  if (!statusData.data?.statusMessages.length) {
    return null;
  }

  const title: string =
    statusData.data.statusMessages.length > 1
      ? intl.formatMessage({
          id: 'activity.statusMessage',
          defaultMessage: 'statusmeldinger',
        })
      : intl.formatMessage({
          id: 'activity.oneStatusMessage',
          defaultMessage: 'statusmelding',
        });

  return (
    <CollapsibleContainer
      icon={alert}
      title={title}
      count={statusData.data.statusMessages.length}
    >
      {statusData.data.statusMessages.map((message: StatusMessage) => (
        <StatusTimeAndText
          timestamp={message.timestamp}
          text={message.text.nob}
          key={message.timestamp.toString() + message.text}
        />
      ))}
    </CollapsibleContainer>
  );
};

// Combination of bold timestamp and normal text
const StatusTimeAndText: React.FC<TimeTextProps> = ({ timestamp, text }) => {
  return (
    <StatusText>
      <BoldSpan>
        {format(parseISO(timestamp.toString()), 'HH:mm', {
          locale: nb,
        })}
        {': '}
      </BoldSpan>
      {text}
    </StatusText>
  );
};

const CollapsibleContainer: React.FC<MessageProps> = ({
  icon,
  title,
  count,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <StatusContainer>
      <StatusHeader onClick={() => setIsOpen(!isOpen)}>
        <StatusIcon src={icon} alt='' />
        <StatusTitle>
          {count.toString()} {title}
        </StatusTitle>
        <RotateArrow src={arrow} alt='' isOpen={isOpen} />
      </StatusHeader>
      <StatusBody
        ref={ref}
        style={
          isOpen ? { height: `${ref.current?.scrollHeight}px` } : { height: 0 }
        }
      >
        {children}
      </StatusBody>
    </StatusContainer>
  );
};

export default StatusMessages;

// Props types

type StatusProps = {
  locationActivity: LocationActivity;
  selectedDate: Date;
};

type TimeTextProps = {
  timestamp: Date;
  text: string;
};

type MessageProps = {
  icon: any;
  title: string;
  count: number;
  children: ReactNode;
};
