import { theme } from '@chakra-ui/react';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';
import { isWideScreen } from 'shared/utils/utils';
import styled from 'styled-components';

interface MainContainerProps {
  isActiveSearch: boolean;
}

export const SearchContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) =>
    props.isActiveSearch
      ? props.theme.secondaryBackground
      : props.theme.primaryBackground};
`;

export const TimePaddingContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
`;

export const TopPaddedContainer = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
  background-color: ${({ theme }): string => theme.primaryBackground};
`;

export const TopContentContainer = styled.div`
  max-width: ${({ theme }): string => theme.widthGlobal};
  width: 100%;
  margin: 0 auto;
  padding-top: 5.5rem;
  padding-bottom: 1.5rem;
`;

export const BottomPaddedContainer = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
`;

export const BottomContentContainer = styled.div<MainContainerProps>`
  max-width: ${({ theme }): string => {
    return isWideScreen(useWindowDimensions().width)
      ? theme.widthWideGlobal
      : theme.widthGlobal;
  }};
  width: ${(props) =>
    props.isActiveSearch ? '100%' : props.theme.widthGlobal};
  margin: 0 auto;
  min-height: 75vh;
  padding-bottom: 1rem;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    &:first-child {
      margin-right: 6px;
    }
  }
`;
