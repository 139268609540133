import React, { useRef, useState } from 'react';
import MenuOpenCloseBtn from '../MenuOpenCloseBtn/MenuOpenCloseBtn';
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import MenuItems from '../MenuItems/MenuItems';

const MenuContainer: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setMenuOpen(false));

  return (
    <div ref={node}>
      <MenuItems isOpen={menuOpen} />
      <MenuOpenCloseBtn isOpen={menuOpen} setOpen={setMenuOpen} />
    </div>
  );
};

export default MenuContainer;
