/**
 * External dependencies
 */
import styled from 'styled-components';
import button from '../button';

interface AdditionalButtonProps {
  isTransparent?: boolean;
}

const additionalButton = styled(button)<AdditionalButtonProps>`
  background-color: ${(props) =>
    props.isTransparent
      ? 'transparent'
      : props.theme.btnAdditionalDefaultBackground};
  color: ${({ theme }): string => theme.btnAdditionalDefaultTextColor};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }): string => theme.btnAdditionalDefaultBorderColor};
  font-weight: 400;

  :focus-visible {
    border-color: ${({ theme }): string => theme.btnAdditionalFocusBorderColor};
    border-width: 2px;
    border-style: solid;
  }

  :hover {
    border-color: ${({ theme }): string => theme.btnAdditionalHoverBorderColor};
    background-color: ${({ theme }): string =>
      theme.btnAdditionalHoverBackground};
  }

  :active {
    background-color: ${({ theme }): string =>
      theme.btnAdditionalActiveBackground};
    border-color: ${({ theme }): string =>
      theme.btnAdditionalActiveBorderColor};
  }

  :disabled {
    border-width: 2px;
    border-color: ${({ theme }): string =>
      theme.btnAdditionalDisabledBorderColor};
    color: ${({ theme }): string => theme.btnAdditionalDisabledTextColor};
    background-color: ${({ theme }): string =>
      theme.btnAdditionalDisabledBackground};
    padding: 14px 18px;
  }
`;

export const AdditionalButtonLarge = styled(additionalButton)`
  font-size: 18px;
  line-height: 24px;
  padding: 15px 18px;

  :focus-visible,
  :disabled {
    padding: 14px 17px;
  }
`;

export const AdditionalButtonMedium = styled(additionalButton)`
  font-size: 18px;
  line-height: 24px;
  padding: 9px 18px;

  :focus-visible,
  :disabled {
    padding: 8px 17px;
  }
`;

export const AdditionalButtonSmall = styled(additionalButton)`
  font-size: 16px;
  line-height: 22px;
  padding: 7px 18px;

  :focus-visible,
  :disabled {
    padding: 6px 17px;
  }
`;

export const AdditionalButtonExtraSmall = styled(additionalButton)`
  font-size: 16px;
  line-height: 22px;
  padding: 6px 12px;

  :focus-visible,
  :disabled {
    padding: 5px 11px;
  }
`;
