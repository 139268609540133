/* eslint-disable consistent-return */
import { hasAccessToken } from 'auth/loginFlowTypes';
import { getState } from 'auth/storage';
import axios, { AxiosRequestConfig } from 'axios';

const getAccessToken = () => {
  const state = getState();

  if (hasAccessToken(state)) {
    return state.accessToken;
  }

  throw new Error('Api attempting use accesstoken when it is not available');
};
const buildAuthHeader = (): string => `Bearer ${getAccessToken()}`;

(axios.defaults.headers as any).common['Content-Type'] =
  'application/json;charset=utf-8';

const requestConfig = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: buildAuthHeader(),
    },
    params: {},
  };
};

export default requestConfig;
