import React from 'react';
import { VehicleSet } from 'api/apiDataTypes';
import { OpenDetailsCallback } from 'pages/SearchPage/useSearchPageController';
import { VehicleSetDetails } from '../SearchResultsDetails/VehicleSetDetails';

interface VehicleSetListProps {
  openDetails: OpenDetailsCallback;
  vehicleSets: VehicleSet[];
  vehicleSetId: string | null;
}

const VehicleSetList: React.FC<VehicleSetListProps> = ({
  openDetails,
  vehicleSets,
  vehicleSetId,
}) => {
  return (
    <>
      {vehicleSets.map((vehicleSet) => (
        <VehicleSetDetails
          key={vehicleSetId}
          vehicleSetId={vehicleSetId}
          isOpen={false}
          data={vehicleSet}
          onClickBack={() => {}}
          onClickOpen={() => {
            openDetails({ vehicleSet });
          }}
        />
      ))}
    </>
  );
};

export default VehicleSetList;
