import messages_no from '../translations/no.json';
import messages_en from '../translations/en.json';
import messages_se from '../translations/se.json';
import { getStoredLocale } from './localStorageUtils';

interface Msgs {
  [key: string]: Record<string, string>;
}

const messages: Msgs = {
  nb: messages_no,
  en: messages_en,
  se: messages_se,
};

export const getLocale = (): string => {
  const selectedLanguage = getStoredLocale();
  const browserLanguage = navigator.language.split(/[-_]/)[0];
  const defaultLanguage = 'nb';
  return selectedLanguage || browserLanguage || defaultLanguage;
};

export const getLocalizedMessages = (): Record<string, string> =>
  messages[getLocale()];
