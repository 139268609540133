const accessTokenKey = 'access_token';
const authUrlKey = 'auth_url';
const clientIdKey = 'client_id';
const jwksIssuerKey = 'jwks_issuer';
const userDataKey = 'user_data';
const localeKey = 'locale_language';
const recentSearchesKey = 'recent_searches';
const installDismissed = 'install_dismissed';

export const setAccessToken = (token: string): void => {
  localStorage.setItem(accessTokenKey, token);
};

export const getClientId = (): string | null => {
  return localStorage.getItem(clientIdKey);
};

export const storeClientId = (clientId: string): void => {
  localStorage.setItem(clientIdKey, clientId);
};

export const getjwksIssuer = (): string | null => {
  return localStorage.getItem(jwksIssuerKey);
};

export const storeAuthUrl = (authUrl: string): void => {
  localStorage.setItem(authUrlKey, authUrl);
};

export const getIsInstallBannerDismissed = (): boolean => {
  return localStorage.getItem(installDismissed) === 'true';
};

export const setDismissedInstallBanner = (dismissed: boolean): void => {
  localStorage.setItem(installDismissed, dismissed ? 'true' : 'false');
};

export const getUserData = (): string | null => {
  return localStorage.getItem(userDataKey);
};

export const setUserData = (userData: any): void => {
  localStorage.setItem(userDataKey, userData);
};

export const removeUserData = (): void => {
  localStorage.removeItem(userDataKey);
};

export const getStoredLocale = (): string | null => {
  return localStorage.getItem(localeKey);
};

export const setStoredLocale = (language: string): void => {
  localStorage.setItem(localeKey, language);
};

export const getRecentSearches = (): string[] => {
  const recentSearches = localStorage.getItem(recentSearchesKey);
  if (!recentSearches) {
    return [];
  }

  try {
    return JSON.parse(recentSearches);
  } catch (e) {
    console.log(
      'Could not get recent searches from local storage due to an error. Resetting recent searches. ',
      e,
    );
    return JSON.parse('[]');
  }
};

export const removeRecentSearches = (): void => {
  localStorage.removeItem(recentSearchesKey);
};

export const addRecentSearch = (recentSearch: string): void => {
  const currentRecentSearches = getRecentSearches();
  if (currentRecentSearches) {
    try {
      if (!currentRecentSearches.includes(recentSearch)) {
        currentRecentSearches.push(recentSearch);
        localStorage.setItem(
          recentSearchesKey,
          JSON.stringify(currentRecentSearches),
        );
      }
    } catch (e) {
      console.log(
        `Could not add ${recentSearch} to recent searches due to: `,
        e,
      );
    }
  } else {
    localStorage.setItem(recentSearchesKey, JSON.stringify([recentSearch]));
  }
};
