import React from 'react';
import styled from 'styled-components';
import CheckboxIcon from './CheckboxIcon';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface StyledCheckboxProps {
  checked: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 22px;
  height: 22px;
  background: ${(props) =>
    props.checked
      ? props.theme.checkboxCheckedBackground
      : props.theme.checkboxBackground};
  border-radius: 2px;
  border-width: 2px;
  border-color: ${(props) =>
    props.checked
      ? props.theme.checkboxCheckedBorderColor
      : props.theme.checkboxBorderColor};
  border-style: solid;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`;

const CheckboxTextContainer = styled.div`
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
`;

interface CheckboxProps<T extends string> {
  checked: boolean;
  children?: React.ReactNode;
  id: T;
  handleChecked: (id: T, checked: boolean) => void;
}

const Checkbox = <T extends string>({
  checked,
  children,
  id,
  handleChecked,
}: CheckboxProps<T>) => (
  <CheckboxTextContainer onClick={() => handleChecked(id, checked)}>
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} id={id} readOnly />
      <StyledCheckbox checked={checked}>
        {checked && <CheckboxIcon />}
      </StyledCheckbox>
    </CheckboxContainer>
    {children}
  </CheckboxTextContainer>
);

export default Checkbox;
