import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from 'shared/styles/texts';
import { SensorData, FilterId, SensorGroup } from 'api/apiDataTypes';
import { processSensors } from 'shared/utils/sensorUtils';
import { AdditionalButtonExtraSmall } from 'shared/components/Button/AdditionalButton/AdditionalButton';
import SliderSwitch from 'shared/components/SliderSwitch/SliderSwitch';
import { getTranslatedAlarmString } from './AlarmSearchResultsUtils';
import AlarmEntry from './AlarmEntry';

interface AlarmSearchResultsListProps {
  data: SensorData[];
  alarmFilters: FilterId[];
  triggerSearch: (value: string) => void;
}

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;

  & > * {
    margin-right: 6px;
    margin-bottom: 6px;
  }
`;

const SwitchContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 12px;
`;

export const AlarmSearchResultsList: React.FC<AlarmSearchResultsListProps> = ({
  data,
  alarmFilters,
  triggerSearch,
}: AlarmSearchResultsListProps) => {
  const intl = useIntl();
  const [showDetailed, setShowDetailed] = useState(false);

  const separatorWord = intl.formatMessage({
    id: 'alarmSearchResults.separatorWord',
    defaultMessage: 'eller',
  });

  const translatedAlarmsString = alarmFilters
    .map((urlParam) => getTranslatedAlarmString(urlParam)?.toLowerCase())
    .join(', ')
    .replace(/, ([^,]*)$/, ` ${separatorWord} $1`);

  const vehicleData = data.map(processSensors);
  const sensorFilters: SensorGroup[] = alarmFilters.map(
    (alarm) => SensorGroup[alarm],
  );
  const vehicleDataWithAlarms = vehicleData.filter((vehicle) =>
    vehicle.processedSensors.some(
      (sensor) =>
        sensor.isPastTreshold && sensorFilters.includes(sensor.sensor.group),
    ),
  );

  const isEmpty = vehicleDataWithAlarms.length === 0;

  if (isEmpty)
    return (
      <Text
        style={{ textAlign: 'center', margin: '2rem 0 1rem', flexWrap: 'wrap' }}
      >
        <FormattedMessage
          id='alarmSearchResults.emptyResult'
          defaultMessage='Ingen kjøretøy med alarmer på'
        />{' '}
        {translatedAlarmsString}
      </Text>
    );

  return (
    <>
      <Text
        style={{ textAlign: 'left', margin: '2rem 0 1rem', flexWrap: 'wrap' }}
      >
        <FormattedMessage
          id='alarmSearchResults.vehicleWithAlarms'
          defaultMessage='Kjøretøy med alarmer på'
        />{' '}
        {translatedAlarmsString}:
      </Text>
      <ButtonContainer>
        {vehicleDataWithAlarms.map((vehicle) => (
          <AdditionalButtonExtraSmall
            key={vehicle.vehicleSetId}
            onClick={() => {
              triggerSearch(vehicle.vehicleSetId);
            }}
            isTransparent
          >
            {vehicle.vehicleSetId}
          </AdditionalButtonExtraSmall>
        ))}
      </ButtonContainer>
      <SwitchContainer>
        <SliderSwitch
          title='Show alarm details'
          isChecked={showDetailed}
          onChange={() => setShowDetailed(!showDetailed)}
          checkedTextId='alarmSearchResults.hideAlarmDetails'
          checkedDefaultText='Skjul alarmdetaljer'
          uncheckedTextId='alarmSearchResults.showAlarmDetails'
          uncheckedDefaultText='Vise alarmdetaljer'
        />
      </SwitchContainer>
      {showDetailed &&
        vehicleDataWithAlarms.map((vehicle) => (
          <AlarmEntry
            key={vehicle.vehicleSetId}
            vehicleData={vehicle}
            triggerSearch={triggerSearch}
          />
        ))}
    </>
  );
};
