import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery } from '../../shared/utils/routeUtils';
import { FilterId, LocationActivity, VehicleSet } from '../../api/apiDataTypes';
import { mapAlarmStringsToFilterIds } from '../../shared/utils/sensorAlarmsUtils';
import { getFormattedDate } from '../../shared/utils/dateUtils';

export type OpenDetailsCallback = ({
  activity,
  vehicleSet,
}: {
  activity?: LocationActivity;
  vehicleSet?: VehicleSet;
}) => void;

type SearchPageState = {
  query: string;
  alarms: FilterId[];
  setTypes: string[];
  date: Date;
  vehicleSetId: string;
  arrivalTrainId: string;
  departureTrainId: string;
};

const createSearchPageStateFromQuery = (
  queryParams: URLSearchParams,
): SearchPageState => {
  const alarmsUrlParam = queryParams.get('alarms');
  const setTypesUrlParam = queryParams.get('setTypes');
  const queryUrlParam = queryParams.get('query');
  const dateUrlParam = queryParams.get('date');
  const vehicleSetIdUrlParam = queryParams.get('vehicleSetId');
  const arrivalTrainIdUrlParam = queryParams.get('arrivalTrainId');
  const departureTrainIdUrlParam = queryParams.get('departureTrainId');

  return {
    query: queryUrlParam || '',
    alarms: alarmsUrlParam ? mapAlarmStringsToFilterIds(alarmsUrlParam) : [],
    setTypes: setTypesUrlParam ? setTypesUrlParam.split(',') : [],
    date: dateUrlParam
      ? new Date(dateUrlParam)
      : new Date(getFormattedDate(new Date())),
    vehicleSetId: vehicleSetIdUrlParam || '',
    arrivalTrainId: arrivalTrainIdUrlParam || '',
    departureTrainId: departureTrainIdUrlParam || '',
  };
};

const getQueryParamsFromSearchPageState = (state: SearchPageState): string => {
  if (
    state.query === '' &&
    state.alarms.length === 0 &&
    state.setTypes.length === 0
  ) {
    return '';
  }

  const params = new URLSearchParams();

  const updateParam = (key: string, value: string) => {
    if (value) {
      params.append(key, value);
    } else {
      params.delete(key);
    }
  };

  updateParam('query', state.query);
  updateParam('alarms', state.alarms.join(','));
  updateParam('setTypes', state.setTypes.join(','));
  updateParam('date', getFormattedDate(state.date));
  updateParam('vehicleSetId', state.vehicleSetId);
  updateParam('arrivalTrainId', state.arrivalTrainId);
  updateParam('departureTrainId', state.departureTrainId);

  return params.toString();
};

const updateSearchPageStateFromDetails = (
  prevState: SearchPageState,
  activity?: LocationActivity,
  vehicleSet?: VehicleSet,
) => {
  if (activity) {
    return {
      ...prevState,
      vehicleSetId: activity.vehicleSetId,
      arrivalTrainId: activity.arrivalTrainId,
      departureTrainId: activity.departureTrainId,
    };
  }

  if (vehicleSet) {
    return { ...prevState, vehicleSetId: vehicleSet.vehicleSetId };
  }

  return prevState;
};

export const useSearchPageController = () => {
  const history = useHistory();
  const queryParams = useQuery();

  const initialState = createSearchPageStateFromQuery(queryParams);
  const [state, setState] = useState(initialState);

  const [searchValue, setSearchValue] = useState(state.query);

  const updateQueryParams = (state: SearchPageState) => {
    const newQueryParams = getQueryParamsFromSearchPageState(state);

    if (newQueryParams !== queryParams.toString()) {
      history.push({ search: newQueryParams });
    }
  };

  const updateState = (update: Partial<SearchPageState>) => {
    setState((prevState) => ({
      ...prevState,
      ...update,
    }));
  };

  useEffect(() => {
    const unregisterListener = window.addEventListener('popstate', () => {
      const nextState = createSearchPageStateFromQuery(
        new URLSearchParams(document.location.search),
      );

      setState(nextState);
      setSearchValue(nextState.query);
    });

    return unregisterListener;
  }, []);

  useEffect(() => {
    updateQueryParams(state);
  }, [state]);

  useEffect(() => {
    if (searchValue === '') updateState({ query: searchValue });
  }, [searchValue]);

  const openDetails: OpenDetailsCallback = ({
    activity,
    vehicleSet,
  }: {
    activity?: LocationActivity;
    vehicleSet?: VehicleSet;
  }) => {
    window.scrollTo(0, 0);
    setState((prevState) =>
      updateSearchPageStateFromDetails(prevState, activity, vehicleSet),
    );
  };

  return {
    searchQuery: state.query,
    setSearchQuery: (query: string) => {
      updateState({
        query,
        arrivalTrainId: '',
        vehicleSetId: '',
        departureTrainId: '',
      });
    },
    selectedDate: state.date,
    setSelectedDate: (date: Date) =>
      updateState({
        date,
        arrivalTrainId: '',
        vehicleSetId: '',
        departureTrainId: '',
      }),
    searchValue,
    setSearchValue,
    alarmFilters: state.alarms,
    setAlarmFilters: (alarms: FilterId[]) =>
      updateState({
        alarms,
        arrivalTrainId: '',
        vehicleSetId: '',
        departureTrainId: '',
      }),
    setTypeFilters: state.setTypes,
    setSetTypeFilters: (setTypes: string[]) =>
      updateState({
        setTypes,
        arrivalTrainId: '',
        vehicleSetId: '',
        departureTrainId: '',
      }),
    openDetails,
    vehicleSetId: state.vehicleSetId,
    arrivalTrainId: state.arrivalTrainId,
    departureTrainId: state.departureTrainId,
  };
};
