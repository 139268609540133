// Styles and styleProps for status messages
import React from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  isOpen: boolean;
};

const fadeInAnimation = keyframes`
   0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const StatusContainer = styled.div`
  background: ${({ theme }): string => `${theme.statusMessageColor}`};
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  font-family: VySans;
  font-size: 16px;
  line-height: 20px;
  animation: ${fadeInAnimation} 0.6s ease;

  &:hover {
    box-shadow: 0 2px 6px #ebebec;
  }
`;

const StatusHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  justify-content: space-between;
  animation: ${fadeInAnimation} 0.6s ease;
  align-items: center;
  cursor: pointer;
  font-family: VySans-Bold;
  -webkit-tap-highlight-color: transparent;
`;

const StatusTitle = styled.p`
  flex-grow: 1;
  text-align: left;
  margin-left: 0.5rem;
`;

const StatusIcon = styled.img`
  flex-grow: 0;
  width: 1.5rem;
`;

const StatusBody = styled.div`
  overflow: hidden;
  height: 0;
  transition: height ease 0.3s;
`;

const RotateArrow = styled.img`
  width: 1.5rem;
  transition: transform 0.3s ease;
  transform: ${(props: Props): string =>
    props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const BoldSpan = styled.span`
  font-family: VySans-Bold;
`;

const StatusText = styled.p`
  padding: 0 1.5rem 0 3rem;
`;

export {
  StatusBody,
  StatusHeader,
  StatusContainer,
  RotateArrow,
  StatusText,
  BoldSpan,
  StatusTitle,
  StatusIcon,
};
