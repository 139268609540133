import React from 'react';
import { LocationActivity } from 'api/apiDataTypes';
import { OpenDetailsCallback } from 'pages/SearchPage/useSearchPageController';
import { ActivityDetails } from '../SearchResultsDetails/ActivityDetails';
import { getUniqueKey } from './searchResultsListUtils';

interface ActivityListProps {
  openDetails: OpenDetailsCallback;
  selectedDate: Date;
  alarmFilteredActivities: LocationActivity[];
}

const ActivityList: React.FC<ActivityListProps> = ({
  openDetails,
  selectedDate,
  alarmFilteredActivities,
}) => {
  return (
    <>
      {alarmFilteredActivities.map((activity) => (
        <ActivityDetails
          isOpen={false}
          key={getUniqueKey(activity)}
          selectedDate={selectedDate}
          data={activity}
          onClickBack={() => {}}
          onClickOpen={() => {
            openDetails({ activity });
          }}
        />
      ))}
    </>
  );
};

export default ActivityList;
