import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCappedString } from 'shared/utils/utils';
import { LocationActivity } from 'api/apiDataTypes';
import { SubText, Text } from 'shared/styles/texts';
import { BackButton } from 'shared/components/BackButton/BackButton';
import VehicleInformation from '../VehicleInformation/VehicleInformation';
import StatusMessages from '../StatusMessage/StatusMessage';
import PlannedActivities from '../PlannedActivities/PlannedActivities';
import {
  SearchDetailsContainer,
  BackButtonContainer,
  SearchDetailsWrapper,
  SearchDetailsSubContainer,
} from './styles';
import TrainTimeField from './TrainTimeField';

interface ActivityDetailsProps {
  data: LocationActivity;
  onClickOpen: () => void;
  onClickBack: () => void;
  isOpen: boolean;
  selectedDate: Date;
}

export const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  data,
  onClickOpen,
  onClickBack,
  isOpen,
  selectedDate,
}) => {
  const intl = useIntl();
  const [isAlert, setIsAlert] = useState(false);
  const notifyAlert = (): void => {
    setIsAlert(true);
  };

  // show the text if the number is not null or 0
  const coupledPositionText = data.coupledPosition
    ? `${data.coupledPosition}: `
    : '';

  const location = data.locationName
    ? getCappedString(data.locationName, 15)
    : data.location;
  const origin = data.originName
    ? getCappedString(data.originName, 15)
    : data.origin;
  const destination = data.destinationName
    ? getCappedString(data.destinationName, 15)
    : data.destination;

  const isStandbyActivity = !data.departureTrainId && !data.arrivalTrainId;

  return (
    <SearchDetailsWrapper
      onClick={() => {
        if (!isOpen) {
          onClickOpen();
        }
      }}
    >
      {isOpen && (
        <BackButtonContainer>
          <BackButton onClick={() => onClickBack()}>
            {intl.formatMessage({
              id: 'details.back',
              defaultMessage: 'Tilbake',
            })}
          </BackButton>
        </BackButtonContainer>
      )}

      <SearchDetailsContainer isOpen={isOpen}>
        <SearchDetailsSubContainer>
          <Text bold style={{ fontSize: '18px' }}>
            {data.arrivalTrainId ? data.arrivalTrainId : data.departureTrainId}
          </Text>
          <SubText
            style={{ marginLeft: `${isStandbyActivity ? 'none' : '5px'}` }}
          >
            {coupledPositionText}
            {data.vehicleSetId}
          </SubText>
          <Text style={{ marginLeft: 'auto' }}>
            {location}
            {trackText(data.scheduledArrivalTrack, data.scheduledArrivalTime)}
          </Text>
        </SearchDetailsSubContainer>

        <SearchDetailsSubContainer>
          <div>
            <TrainTimeField
              scheduledTime={data.scheduledArrivalTime}
              estimatedTime={data.estimatedArrivalTime}
              actualTime={data.actualArrivalTime}
              title={intl.formatMessage({
                id: 'activity.arrival',
                defaultMessage: 'Ankomst',
              })}
              location={intl.formatMessage(
                {
                  id: 'activity.fromStation',
                  defaultMessage: 'Fra',
                },
                {
                  st: origin,
                },
              )}
            />
            <TrainTimeField
              scheduledTime={data.scheduledDepartureTime}
              estimatedTime={data.estimatedDepartureTime}
              actualTime={data.actualDepartureTime}
              title={intl.formatMessage({
                id: 'activity.departure',
                defaultMessage: 'Avgang',
              })}
              location={intl.formatMessage(
                {
                  id: 'activity.toStation',
                  defaultMessage: 'Til',
                },
                {
                  st: destination,
                },
              )}
            />
          </div>
        </SearchDetailsSubContainer>

        <SearchDetailsSubContainer>
          <SubText>
            {trainText(data.departureTrainId, location, isStandbyActivity)}
            {trackText(
              data.scheduledDepartureTrack,
              data.scheduledDepartureTime,
            )}
          </SubText>
        </SearchDetailsSubContainer>
      </SearchDetailsContainer>

      {isOpen && (
        <SearchDetailsContainer
          isOpen={isOpen}
          style={{ padding: '0px', rowGap: '0px' }}
        >
          <StatusMessages locationActivity={data} selectedDate={selectedDate} />
          <PlannedActivities locationActivity={data} />
          <VehicleInformation
            vehicleSetId={data.vehicleSetId}
            notifyAlert={notifyAlert}
          />
        </SearchDetailsContainer>
      )}
    </SearchDetailsWrapper>
  );
};

const trackText = (track: string, time: string) => {
  if (time && track)
    return (
      <FormattedMessage
        id='activity.trackNr'
        values={{
          track,
        }}
        defaultMessage={`, spor ${track}`}
      />
    );
  if (time && !track)
    return (
      <FormattedMessage
        id='activity.trackUnknown'
        defaultMessage=' spor ukjent'
      />
    );
  return null;
};

const trainText = (
  departureTrainId: string,
  location: string,
  isStandbyActivity: boolean,
) => {
  if (departureTrainId) {
    return (
      <FormattedMessage
        id='activity.departureAs'
        values={{
          id: departureTrainId,
        }}
        defaultMessage='Avgang som tog {id}'
      />
    );
  }
  if (isStandbyActivity) {
    return (
      <FormattedMessage
        id='activity.standbyIn'
        values={{
          loc: location,
        }}
        defaultMessage='Standby i {loc}'
      />
    );
  }
  return null;
};
