import * as colors from '../../../styles/colors';

export const defaultTheme = {
  // Square
  squareBtnDefaultTextColor: colors.darkGrey,
  squareBtnDefaultBorderColor: colors.lightBlue,
  squareBtnDefaultBackground: colors.lightBlue60,

  squareBtnHoverBackground: colors.lightGreen20,

  squareBtnActiveBackground: colors.lightBlue,

  squareBtnDisabledBackground: colors.blueGrey60,
};
