import React, { RefObject } from 'react';
import { onSensorThreshold, SensorThresholds } from 'shared/utils/sensorUtils';
import { nb } from 'date-fns/locale';
import { format } from 'date-fns';
import {
  returnColorType,
  returnDashArray,
  returnSensorGroupType,
  returnUnitMaxValue,
  returnUnitMinValue,
} from './GraphTypeConverters';
import { GroupDto, SensorData, SignalObjectDto } from './GraphTypes';

const d3 = require('d3');

export type DrawGraphProps = {
  vizRef: RefObject<HTMLDivElement>;
  historyData: GroupDto;
  setWarning: React.Dispatch<React.SetStateAction<boolean>>;
  timePeriod: number;
  theme: any;
  wideGraph: boolean;
};

export function drawGraph({
  vizRef,
  historyData,
  setWarning,
  timePeriod,
  theme,
  wideGraph,
}: DrawGraphProps) {
  const maxUnit = returnUnitMaxValue(historyData.unit, historyData.groupName);
  const minUnit = returnUnitMinValue(historyData.unit, historyData.groupName);

  const bisectDate = d3.bisector((data: SensorData) => {
    return parseInt(data.timestamp, 10) * 1000;
  }).left;

  const margin = { top: 10, right: 20, bottom: 30, left: 40 };
  const yAxisBottomMargin = 1.15;
  const width = (wideGraph ? 720 : 320) - margin.right;
  const height =
    (wideGraph ? 310 : 250) + yAxisBottomMargin - margin.top - margin.bottom;

  let startWarningField = 0;
  let endWarningField = 0;

  const domainEndDate = new Date().getTime();

  d3.select(vizRef.current).selectAll('*').remove();

  const x = d3
    .scaleTime()
    .domain([domainEndDate - timePeriod, domainEndDate])
    .range([0, width]);

  const y = d3
    .scaleLinear()
    .domain([minUnit, maxUnit])
    .range([height - yAxisBottomMargin, 0])
    .nice();

  const returnWarningValues = (value: string) => {
    if (value === 'water') {
      startWarningField = height - y(SensorThresholds.water);
      endWarningField = height - startWarningField;
    } else if (value === 'septic') {
      startWarningField = height - y(100 - SensorThresholds.septic);
      endWarningField = 0;
    } else if (value === 'batteryVoltage') {
      startWarningField = height - y(SensorThresholds.batteryVoltage);
      endWarningField = height - startWarningField;
    } else if (value === 'batteryChargerVoltage') {
      startWarningField = height - y(SensorThresholds.batteryChargerVoltage);
      endWarningField = height - startWarningField;
    }
  };

  const tooltipWidth = 160;

  const svg = d3
    .select(vizRef.current)
    .append('svg')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    .attr('viewBox', `0 0 ${wideGraph ? '750 310' : '370 270'}`)
    .attr('class', 'graph-area')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const textField = d3
    .select(vizRef.current)
    .append('svg')
    .attr('class', 'text-area')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    .attr('viewBox', `0 0 ${wideGraph ? 560 : 370} ${60}`)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  returnWarningValues(historyData.groupName);

  svg
    .append('rect')
    .attr('class', 'warning-zone')
    .attr('width', width)
    .attr('height', startWarningField)
    .attr('x', 0)
    .attr('y', endWarningField)
    .attr('rx', 0)
    .attr('ry', 0)
    .attr('fill', theme.thresholdColor);

  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(
      d3
        .axisBottom(x)
        .ticks(
          timePeriod > 86400 * 1000 || wideGraph ? 7 : 5,
          timePeriod <= 86400 * 1000
            ? d3.timeFormat('%H:%M')
            : d3.timeFormat('%d/%m'),
        ),
    );

  svg.append('g').call(d3.axisLeft(y).ticks(5));

  svg
    .append('text')
    .attr('transform', 'rotate(0)')
    .attr('class', 'y-label')
    .attr('y', -6)
    .attr('x', -32)
    .attr('dy', '1em')
    .attr('font-size', '10px')
    .style('text-anchor', 'middle')
    .text(historyData.unit);

  const findClosestDataPoint = (
    values: SensorData[],
    mousePositionX: Date,
    vehicleType: string,
  ): [SensorData, string] => {
    const mapOfVehicleTypeValues: SensorData[] = values;

    const indexOfClosestDataPoint = bisectDate(
      mapOfVehicleTypeValues,
      mousePositionX,
    );

    const closestDataPoint =
      mapOfVehicleTypeValues[indexOfClosestDataPoint - 1];

    return [closestDataPoint, vehicleType];
  };

  const convertMsToDate = (ms: string) => {
    return new Date(parseInt(ms, 10) * 1000);
  };

  const mouseMove = (data: string) => {
    const mousePositionX = x.invert(d3.pointer(data)[0]);

    const tooltipData = historyData.signals.map(
      (signalObject: SignalObjectDto): [SensorData, string] => {
        return findClosestDataPoint(
          signalObject.values,
          mousePositionX,
          signalObject.vehicleType,
        );
      },
    );

    let tooltipDate = '';
    let tooltipTimestamp = '';

    if (tooltipData[0][0] != null) {
      tooltipTimestamp = format(
        convertMsToDate(tooltipData[0][0].timestamp),
        'HH:mm',
        { locale: nb },
      );
      tooltipDate = format(
        convertMsToDate(tooltipData[0][0].timestamp),
        'dd/MM',
        { locale: nb },
      );
    }

    const returnCoordinates = (additionalPixels: number) => {
      if (Math.floor(d3.pointer(data)[0]) < 0) {
        return additionalPixels;
      }
      if (Math.floor(d3.pointer(data)[0]) > width - tooltipWidth) {
        return width - tooltipWidth + additionalPixels;
      }
      return d3.pointer(data)[0] + additionalPixels;
    };

    tooltipLine
      .attr('stroke', theme.tooltipColor)
      .attr('x1', d3.pointer(data)[0])
      .attr('x2', d3.pointer(data)[0])
      .attr('y1', 0)
      .attr('y2', height);

    focus
      .select('.tooltip')
      .attr('width', tooltipWidth)
      .attr('height', 60 + tooltipData?.length * 16)
      .attr('x', returnCoordinates(0))
      .attr('y', 0)
      .attr('rx', 4)
      .attr('ry', 4)
      .style('opacity', 0.7);

    focus
      .select('.sensor-timestamp')
      .attr('x', returnCoordinates(10))
      .attr('y', 18)
      .text(`${tooltipDate} - ${tooltipTimestamp}`);

    tooltipData.forEach((tooltipValue: [SensorData, string], index: number) => {
      focus
        .select(`.colored-circle-${tooltipValue[1]}`)
        .attr('cx', returnCoordinates(15))
        .attr('cy', 34 + index * 20)
        .attr('r', 5)
        .attr('fill', returnColorType(tooltipValue[1], theme));
      focus
        .select(`.vehicle-type-${tooltipValue[1]}`)
        .attr('x', returnCoordinates(27))
        .attr('y', 40 + index * 20)
        .text(tooltipValue[1]);
      focus
        .select(`.sensor-value-${tooltipValue[1]}`)
        .attr('x', returnCoordinates(100))
        .attr('y', 40 + index * 20)
        .text(
          tooltipValue[0]?.value
            ? Math.round(parseInt(tooltipValue[0].value, 10) * 10) / 10 +
                historyData.unit
            : '- -',
        );
    });
  };

  // @ts-ignore
  const line = d3
    .line<SensorData>()
    .x((value: { timestamp: { toString: () => string } }) =>
      x(convertMsToDate(value.timestamp.toString())),
    )
    .y((value: { value: string }) => y(parseInt(value.value, 10)));

  svg
    .append('defs')
    .append('svg:clipPath')
    .attr('id', 'clip')
    .append('svg:rect')
    .attr('id', 'clip-rect')
    .attr('x', '0')
    .attr('y', '0')
    .attr('width', width)
    .attr('height', height);

  svg
    .selectAll()
    .data(historyData.signals)
    .enter()
    .append('path')
    .attr('clip-path', 'url(#clip)')
    .attr('fill', 'none')
    .attr('stroke', (signalData: SignalObjectDto) =>
      returnColorType(signalData.vehicleType, theme),
    )
    .attr('stroke-width', 1)
    .style('stroke-dasharray', (signalData: SignalObjectDto) =>
      returnDashArray(signalData.vehicleType),
    )
    .datum((signalData: SignalObjectDto) => signalData.values)
    .attr('d', line);

  let focus = svg.append('g').attr('class', 'focus').style('display', 'none');

  let tooltipLine = focus.append('line');

  focus
    .append('rect')
    .attr('class', 'tooltip')
    .attr('width', 150)
    .attr('height', 120)
    .attr('x', 10)
    .attr('y', 20)
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('fill', theme.tooltipColor)
    .attr('stroke', theme.tooltipColor);

  focus
    .append('text')
    .attr('class', 'sensor-timestamp')
    .attr('x', 18)
    .attr('y', 18)
    .attr('fill', theme.primaryBackground)
    .attr('font-size', '12px')
    .attr('opacity', '70%')
    .text('');

  historyData.signals.forEach(
    (vehicleTypeSignals: SignalObjectDto, i: number) => {
      if (vehicleTypeSignals.values.length > 0) {
        const vehicleValueWarnings: string[] = [];

        const returnWarningColor = (vehicleType: string) => {
          if (vehicleValueWarnings.includes(vehicleType)) {
            return theme.errorColor;
          }
          return theme.text;
        };

        const newestSignal =
          vehicleTypeSignals.values[vehicleTypeSignals.values.length - 1];
        if (
          onSensorThreshold(
            newestSignal.value,
            returnSensorGroupType(historyData.groupName),
          )
        ) {
          vehicleValueWarnings.push(vehicleTypeSignals.vehicleType);
          setWarning(true);
        }

        const returnLinePath = (
          index: number,
          coordinate: number,
          x2: number = 0,
        ) => {
          if (index / 2 === 2) {
            return x2;
          }
          if (index / 2 > 2) {
            return x2 + (index - 4) * coordinate;
          }
          return x2 + index * coordinate;
        };

        textField
          .append('line')
          .attr(
            'stroke',
            returnColorType(vehicleTypeSignals.vehicleType, theme),
          )
          .attr('stroke-width', 2)
          .style(
            'stroke-dasharray',
            returnDashArray(vehicleTypeSignals.vehicleType),
          )
          .attr('x1', returnLinePath(i, 75))
          .attr('x2', returnLinePath(i, 75, 18))
          .attr('y1', i / 2 >= 2 ? 35 : 5)
          .attr('y2', i / 2 >= 2 ? 35 : 5);

        textField
          .append('text')
          .attr('class', 'graph-text')
          .attr('fill', returnWarningColor(vehicleTypeSignals.vehicleType))
          .attr('x', returnLinePath(i, 73, 24))
          .attr('y', i / 2 >= 2 ? 40 : 10)
          .text(vehicleTypeSignals.vehicleType);

        focus
          .append('circle')
          .attr('class', `colored-circle-${vehicleTypeSignals.vehicleType}`);

        focus
          .append('text')
          .attr('class', `sensor-value-${vehicleTypeSignals.vehicleType}`)
          .attr('x', 100)
          .attr('y', 50)
          .attr('fill', theme.primaryBackground)
          .attr('font-size', '14px')
          .text('');
        focus
          .append('text')
          .attr('class', `vehicle-type-${vehicleTypeSignals.vehicleType}`)
          .attr('x', 18)
          .attr('y', 50)
          .attr('fill', theme.primaryBackground)
          .attr('font-size', '14px')
          .text('');
      }
    },
  );

  svg
    .append('rect')
    .attr('class', 'overlay')
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .attr('width', width)
    .attr('height', height)
    .on('mouseover', () => {
      focus.style('display', null);
    })
    .on('mouseout', () => {
      focus.style('display', 'none');
    })
    .on('mousemove', mouseMove)
    .attr('fill', 'none');
}
