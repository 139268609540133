import React from 'react';
import { FilterId, SearchResult } from 'api/apiDataTypes';
import { Loading } from 'shared/components/Loading/Loading';
import { OpenDetailsCallback } from 'pages/SearchPage/useSearchPageController';
import SearchResultsList from './SearchResultsList/SearchResultsList';
import { getEmptyListMessage } from './SearchResultsList/ErrorMessages';

interface SearchContentProps {
  data?: SearchResult;
  openDetails: OpenDetailsCallback;
  selectedDate: Date;
  alarmFilters: FilterId[];
  loading: boolean;
  vehicleSetId: string | null;
  arrivalTrainId: string | null;
  departureTrainId: string | null;
}

const SearchContent: React.FC<SearchContentProps> = ({
  data,
  openDetails,
  selectedDate,
  alarmFilters,
  loading,
  vehicleSetId,
  arrivalTrainId,
  departureTrainId,
}: SearchContentProps) => {
  if (loading) {
    return <Loading loading={loading} />;
  }

  return data ? (
    <SearchResultsList
      searchResult={data}
      openDetails={openDetails}
      selectedDate={selectedDate}
      alarmFilters={alarmFilters}
      vehicleSetId={vehicleSetId}
      arrivalTrainId={arrivalTrainId}
      departureTrainId={departureTrainId}
    />
  ) : (
    getEmptyListMessage()
  );
};

export default SearchContent;
