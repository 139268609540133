import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Tabs, TabList, Tab } from '@vygruppen/spor-react';
import { getSensorDataFromVehicleSetId, getSensorHistory } from 'api';
import { SensorData, HistoryData } from 'api/apiDataTypes';
import AlertContext from 'shared/context/AlertContext';
import { Loading } from 'shared/components/Loading/Loading';
import { getTimestampInSeconds } from 'shared/utils/dateUtils';
import VehicleErrorMessage from './VehicleErrorMessage';
import DataVisualization from '../DataVisualization/DataVisualization';
import GPS from '../GPS/GPS';

const InformationDisplayContainer = styled.div`
  border-radius: 6px;
  margin-top: 12px;
`;

const GpsContainer = styled.div`
  position: relative;
  z-index: 0;
`;

interface VehicleInformationProps {
  vehicleSetId: string;
  notifyAlert: VoidFunction;
}

type InformationDisplayKey = 'Sensor data' | 'Gps';

interface PageOption {
  label: string;
  value: InformationDisplayKey;
}

const SECONDS_IN_WEEK = 604800;
const timestamp = getTimestampInSeconds(SECONDS_IN_WEEK);

const VehicleInformation: React.FC<VehicleInformationProps> = ({
  vehicleSetId,
  notifyAlert,
}) => {
  const intl = useIntl();

  const sensorData = useQuery<SensorData>(
    ['sensorData', vehicleSetId],
    () => getSensorDataFromVehicleSetId({ vehicleSetId }),
    { retry: false },
  );

  const historyData = useQuery<HistoryData>(
    ['historyData', vehicleSetId],
    () => getSensorHistory({ vehicleSetId, timestamp }),
    { retry: false },
  );

  const [informationDisplay, setInformationDisplay] =
    useState<InformationDisplayKey>('Sensor data');

  const setDisplay = (index: number) => {
    setInformationDisplay(pageOptions[index].value);
  };

  const sensorDataTranslation = intl.formatMessage({
    id: 'vehicleInformation.sensorData',
    defaultMessage: 'Sensor data',
  });
  const gpsTranslation = intl.formatMessage({
    id: 'vehicleInformation.position',
    defaultMessage: 'Posisjon',
  });

  const pageOptions: PageOption[] = [
    {
      label: sensorDataTranslation,
      value: 'Sensor data',
    },
    {
      label: gpsTranslation,
      value: 'Gps',
    },
  ];

  if (sensorData.isLoading || historyData.isLoading) {
    return (
      <Loading loading={sensorData.isLoading} style={{ textAlign: 'center' }} />
    );
  }

  const sortedHistoryData = historyData?.data?.groups?.sort(
    (a, b) => a.groupName.localeCompare(b.groupName) * -1,
  );

  return (
    <>
      <Tabs
        variant='square'
        colorScheme='accent'
        size='md'
        isFitted
        onChange={setDisplay}
      >
        <TabList>
          {pageOptions.map((option) => (
            <Tab key={option.label}>{option.label}</Tab>
          ))}
        </TabList>
      </Tabs>
      <InformationDisplayContainer>
        {informationDisplay === 'Sensor data' && (
          <AlertContext.Provider value={notifyAlert}>
            {!historyData.isError && sensorData?.data?.signals ? (
              sortedHistoryData?.map((historyGroup) => (
                <DataVisualization
                  vehicleSetId={vehicleSetId}
                  sensorSignals={sensorData?.data?.signals}
                  historyGroup={historyGroup}
                  key={vehicleSetId + historyGroup.groupName}
                />
              ))
            ) : (
              <VehicleErrorMessage
                vehicleSetId={vehicleSetId}
                sensorType={sensorDataTranslation.toLowerCase()}
              />
            )}
          </AlertContext.Provider>
        )}
        {informationDisplay === 'Gps' &&
          (!sensorData.isError && sensorData?.data?.gps ? (
            <GpsContainer>
              <GPS vehicleSetId={vehicleSetId} gps={sensorData.data.gps} />
            </GpsContainer>
          ) : (
            <VehicleErrorMessage
              vehicleSetId={vehicleSetId}
              sensorType={gpsTranslation.toLowerCase()}
            />
          ))}
      </InformationDisplayContainer>
    </>
  );
};

export default VehicleInformation;
