import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Text } from 'shared/styles/texts';
import Checkbox from 'shared/components/Checkbox/Checkbox';
import { FilterButton } from 'shared/components/FilterButton/FilterButton';
import { InputField } from 'shared/components/VyInput/InputField';
import { Loading } from 'shared/components/Loading/Loading';
import { SearchContainer, CheckboxContainer } from './SetTypeFilterStyles';

interface SetTypeFilterProps {
  allSetTypes: string[];
  setTypeFilters: string[];
  setSetTypeFilters: (setTypes: string[]) => void;
  loading: boolean;
  error: boolean;
}

const SetTypeFilter: React.FC<SetTypeFilterProps> = ({
  allSetTypes,
  setTypeFilters,
  setSetTypeFilters,
  loading,
  error,
}) => {
  const intl = useIntl();
  const [checkedFilters, setCheckedFilters] = useState(setTypeFilters);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (setTypeFilters.join(',') !== checkedFilters.join(',')) {
      setCheckedFilters(setTypeFilters);
    }
  }, [setTypeFilters]);

  const sortedSetTypes = [...allSetTypes].sort(
    (a, b) =>
      Number(setTypeFilters.includes(b)) - Number(setTypeFilters.includes(a)),
  );

  const filteredSetTypes = searchValue.length
    ? sortedSetTypes?.filter((setType) =>
        setType.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : sortedSetTypes;

  const handleCheckedFilters = (setType: string, checked: boolean): void => {
    if (checked) {
      setCheckedFilters(checkedFilters.filter((id) => id !== setType));
    } else {
      setCheckedFilters([...checkedFilters, setType]);
    }
  };

  return (
    <FilterButton
      selectedFilters={setTypeFilters}
      onSelect={() => setSetTypeFilters(checkedFilters)}
      buttonActiveTextId='setTypeFilter.buttonTextActive'
      buttonInactiveTextId='setTypeFilter.buttonTextDefault'
      titleTextId='setTypeFilter.info'
    >
      {!error ? (
        <SearchContainer>
          <InputField
            type='Search'
            onChange={(val) => {
              setSearchValue(val);
            }}
            placeholder={intl.formatMessage({
              id: 'setTypeFilter.search.placeholder',
              defaultMessage: 'Søk etter lokasjon, tognr eller kjøretøy',
            })}
            value={searchValue}
          />
          {!loading && filteredSetTypes ? (
            <CheckboxContainer>
              {filteredSetTypes.map((setType) => (
                <Checkbox
                  key={setType}
                  id={setType}
                  checked={checkedFilters.includes(setType)}
                  handleChecked={handleCheckedFilters}
                >
                  {setType}
                </Checkbox>
              ))}
            </CheckboxContainer>
          ) : (
            <Loading loading={loading} />
          )}
        </SearchContainer>
      ) : (
        <Text style={{ textAlign: 'center', margin: '1rem 0' }}>
          <FormattedMessage
            id='setTypeFilter.noData'
            defaultMessage='Kunne ikke laste togsettyper'
          />
        </Text>
      )}
    </FilterButton>
  );
};

export default SetTypeFilter;
