import axios from 'axios';
import requestConfig from './api';
import apiUrl from './apiUrl';

type SetTypeParams = {
  validAt: Date;
};

const getSetTypeData = (params: SetTypeParams): Promise<string[]> =>
  axios
    .get(`${apiUrl()}/set-types`, {
      ...requestConfig(),
      params,
    })
    .then((res) => res.data);

export default getSetTypeData;
