import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowBackSVG } from './ArrowBack.svg';
import { Text } from '../../styles/texts';

const BackButtonContainer = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
`;

const BackIcon = styled(ArrowBackSVG)`
  margin-right: 5px;
`;

interface BackButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const BackButton = ({ children, onClick }: BackButtonProps) => (
  <BackButtonContainer onClick={onClick}>
    <BackIcon />
    <Text>{children}</Text>
  </BackButtonContainer>
);
