import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'shared/styles/texts';

export const getNoAlarmsMessage = () => (
  <Text style={{ textAlign: 'center', marginTop: '2rem' }}>
    <FormattedMessage
      id='search.noAlarms'
      defaultMessage='Fant ingen treff for valgte alarmer'
    />
  </Text>
);
