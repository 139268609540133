import { LoginFlowStatus } from 'auth/loginFlowTypes';
import useLoginFlow, { useLoginFlowStatus } from 'auth/useLoginFlow';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { LanguageSelectorWithLabel } from 'shared/components/LanguageSelector/LanguageSelector';
import { FullPageLoader } from 'shared/components/Loading/Loading';
import styled from 'styled-components';
import Header from 'shared/components/Header/Header';
import { PrimaryButtonLarge } from 'shared/components/Button/PrimaryButton/PrimaryButton';

const LoginContainer = styled.div`
  margin: 0 auto;
  width: 340px;
  max-width: 100%;
  margin-top: 100px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & > * + * {
    margin-top: 30px;
  }
`;

const LoginPage: React.FC = () => {
  const { state, login, fetchSettings, reset } = useLoginFlow();
  const loginInProgressStates: LoginFlowStatus[] = [
    'LoginSettingsLoading',
    'LoginSettingsSuccess',
    'PkceCodeLoading',
    'PkceCodeSuccess',
    'TokenLoading',
  ];
  const loginInProgress = loginInProgressStates.includes(state.status);

  useEffect(() => {
    // If login is in progress during the first render, assume something has gone wrong
    // and reset login state to LoggedOut
    if (loginInProgress) {
      reset();
    }
  }, []);

  const onLoginButtonClick = () => {
    fetchSettings().then(login);
  };

  if (loginInProgress) {
    return <FullPageLoader />;
  }

  return (
    <LoginContainer data-testid='login-page-wrapper'>
      {state.status === 'Failure' && (
        <div>
          <FormattedMessage
            id='login.generalError'
            defaultMessage='Innlogging utilgjengelig, prøv igjen senere'
          />
        </div>
      )}
      <PrimaryButtonLarge
        dynamic
        data-testid='aws-signin-button'
        onClick={onLoginButtonClick}
      >
        <FormattedMessage id='login.title' defaultMessage='Logg inn' />
      </PrimaryButtonLarge>
      <LanguageSelectorWithLabel />
    </LoginContainer>
  );
};

const AuthenticateAndRedirect: React.FunctionComponent = () => {
  const { state, verifyPkceCode, fetchToken } = useLoginFlow();

  useEffect(() => {
    verifyPkceCode();
  }, []);

  useLoginFlowStatus(state, (status) => {
    if (status === 'Failure') {
      window.location.assign('/');
    }

    if (status === 'PkceCodeSuccess') {
      fetchToken();
    }

    if (status === 'LoggedIn') {
      window.location.assign('/');
    }
  });

  return <FullPageLoader />;
};

const UnauthenticatedApp: React.FC = () => (
  <>
    <Header />
    <Router>
      <Switch>
        <Route exact path='/'>
          <LoginPage />
        </Route>
        <Route exact path='/login-redirect'>
          <AuthenticateAndRedirect />
        </Route>
        <Redirect to='/' />
      </Switch>
    </Router>
  </>
);

export default UnauthenticatedApp;
