import styled from 'styled-components';

export const SensorAlarmFilters = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  & > * {
    padding: 0 10px 18px 0;
    width: 50%;
  }
`;
