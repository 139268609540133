import React from 'react';
import { useQuery } from 'react-query';
import { AlarmSearchResultsList } from 'features/SearchContent/AlarmSearchResults/AlarmSearchResultsList';
import { Loading } from 'shared/components/Loading/Loading';
import { getNoAlarmsMessage } from 'shared/components/ErrorMessages/ErrorMessages';
import { getSensorData } from 'api';
import { FilterId, SensorData, SensorGroup } from 'api/apiDataTypes';

interface AlarmSearchResultsProps {
  triggerSearch: (value: string) => void;
  alarmFilters: FilterId[];
}

export const AlarmSearchResults: React.FC<AlarmSearchResultsProps> = ({
  triggerSearch,
  alarmFilters,
}) => {
  const { data, isLoading } = useQuery<SensorData[]>(
    ['sensorData', alarmFilters],
    () =>
      getSensorData({
        sensors: alarmFilters.map((sensor) => SensorGroup[sensor]),
      }),
    { refetchInterval: 300000 }, // 5 minutes
  );

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  return data ? (
    <AlarmSearchResultsList
      data={data}
      alarmFilters={alarmFilters}
      triggerSearch={triggerSearch}
    />
  ) : (
    getNoAlarmsMessage()
  );
};
