import * as colors from '../../../styles/colors';

export const defaultTheme = {
  btnAdditionalDefaultBackground: colors.backgroundWhite,
  btnAdditionalDefaultTextColor: colors.darkGrey,
  btnAdditionalDefaultBorderColor: colors.darkGrey,

  btnAdditionalFocusBorderColor: colors.lightGreen,

  btnAdditionalActiveBackground: colors.lightBlue,
  btnAdditionalActiveBorderColor: colors.darkGrey,

  btnAdditionalHoverBorderColor: colors.darkGrey,
  btnAdditionalHoverBackground: colors.lightGreen20,

  btnAdditionalDisabledBackground: colors.backgroundWhite,
  btnAdditionalDisabledTextColor: colors.blueGrey60,
  btnAdditionalDisabledBorderColor: colors.blueGrey10,
};
