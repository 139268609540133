import React, { ReactNode, ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProviderWrapper } from '../shared/context/IntlContextWrapper';
import { createBackdropContext } from '../shared/context/BackdropContext';
import defaultTheme from '../shared/styles/themes/default/defaultTheme';
import GlobalStyles from '../shared/styles/themes/default/global';

const { BackdropProvider, useBackdropContext } = createBackdropContext();
export { useBackdropContext };

const AppProvider = (props: { children: ReactNode }): ReactElement => {
  const { children } = props;

  return (
    <IntlProviderWrapper>
      <ThemeProvider theme={defaultTheme}>
        <BackdropProvider>
          <GlobalStyles />
          {children}
        </BackdropProvider>
      </ThemeProvider>
    </IntlProviderWrapper>
  );
};

export default AppProvider;
