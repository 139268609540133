import { timeWithDelay } from './dateUtils';

export const getDelayedTime = (time: string, delay: number): string | null => {
  return delay ? timeWithDelay(time, 'HH:mm', delay) : null;
};

export const getCappedString = (string: string, maxChars: number) => {
  const dots = string.length > maxChars ? '...' : '';
  return string.slice(0, maxChars) + dots;
};

export const unique = (list: number[]): number[] => {
  return Array.from(new Set(list));
};

export const isWideScreen = (screenWidth: number): boolean => {
  return screenWidth > 1000;
};
