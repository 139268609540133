import * as colors from '../../../styles/colors';

export const defaultTheme = {
  btnPrimaryDefaultBackground: colors.actionGreen,
  btnPrimaryDefaultTextColor: colors.backgroundWhite,

  btnPrimaryFocusBackground: colors.actionGreen,
  btnPrimaryFocusTextColor: colors.backgroundWhite,

  btnPrimaryHoverBackground: colors.darkGreen,

  btnPrimaryActiveBackground: colors.darkGreen,

  btnPrimaryDisabledBackground: colors.blueGrey10,
  btnPrimaryDisabledTextColor: colors.darkGrey,
};
