import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'shared/styles/texts';
import IconAndTextItem from 'shared/components/IconAndTextItem/IconAndTextItem';
import { SensorGroup } from 'api/apiDataTypes';
import { ExpandedSignal } from '../../shared/utils/sensorUtils';
import SignalGroup from './SignalGroup/SignalGroup';

const filterByGroup =
  (group: SensorGroup) =>
  (data: ExpandedSignal): boolean =>
    data.sensor.group === group;

const isNotEmpty = (array: ExpandedSignal[]): boolean => array.length > 0;

type SensorListProps = {
  vehicleSetId: string;
  processedSensors: ExpandedSignal[];
  sensorGroup?: string;
  header?: boolean;
  activeFilters?: SensorGroup[];
};

const sensorGroups = [
  SensorGroup.water,
  SensorGroup.septic,
  SensorGroup.indoorTemperature,
  SensorGroup.outdoorTemperature,
  SensorGroup.batteryVoltage,
  SensorGroup.batteryChargerVoltage,
];

function mapToIcon(group: SensorGroup): string {
  switch (group) {
    case SensorGroup.water:
      return 'water';
    case SensorGroup.septic:
      return 'toilet';
    case SensorGroup.indoorTemperature:
      return 'temp';
    case SensorGroup.outdoorTemperature:
      return 'temp';
    case SensorGroup.batteryVoltage:
      return 'battery';
    case SensorGroup.batteryChargerVoltage:
      return 'battery';
    default:
      return '';
  }
}

function mapToDescriptionId(group: SensorGroup): string {
  switch (group) {
    case SensorGroup.water:
      return 'sensorReadings.waterTank';
    case SensorGroup.septic:
      return 'sensorReadings.septicTank';
    case SensorGroup.indoorTemperature:
      return 'sensorReadings.indoor.temp';
    case SensorGroup.outdoorTemperature:
      return 'sensorReadings.outdoor.temp';
    case SensorGroup.batteryVoltage:
      return 'sensorReadings.battery.voltage';
    case SensorGroup.batteryChargerVoltage:
      return 'sensorReadings.batteryCharger.voltage';
    default:
      return '';
  }
}

function returnSensorGroupType(g: any): SensorGroup {
  if (g === 'water') {
    return SensorGroup.water;
  }
  if (g === 'septic') {
    return SensorGroup.septic;
  }
  if (g === 'indoorTemperature') {
    return SensorGroup.indoorTemperature;
  }
  if (g === 'outdoorTemperature') {
    return SensorGroup.outdoorTemperature;
  }
  if (g === 'batteryVoltage') {
    return SensorGroup.batteryVoltage;
  }
  if (g === 'batteryChargerVoltage') {
    return SensorGroup.batteryChargerVoltage;
  }
  return SensorGroup.all;
}

const SensorList: React.FC<SensorListProps> = (props: SensorListProps) => {
  const {
    processedSensors,
    vehicleSetId,
    sensorGroup: sensorGroupName,
    header,
    activeFilters,
  } = props;

  const groupedSensors = sensorGroups.map((group) => {
    return { sensors: processedSensors.filter(filterByGroup(group)), group };
  });
  const groupType = returnSensorGroupType(sensorGroupName);
  const sensorValues = groupedSensors.filter(
    (sensor) => groupType === SensorGroup.all || sensor.group === groupType,
  );
  const filteredSensorValues = activeFilters?.length
    ? sensorValues.filter(
        (sensorValue) =>
          activeFilters.includes(sensorValue.group) &&
          sensorValue.sensors.length,
      )
    : sensorValues;

  if (groupedSensors.some((sensorGroup) => isNotEmpty(sensorGroup.sensors))) {
    return (
      <div data-testid='sensor-list'>
        {filteredSensorValues.map((item) => (
          <Fragment key={item.group}>
            {header && (
              <IconAndTextItem
                width='200px'
                margin='0px 0px 12px 0px'
                data-testid='iconAndText'
                icon={mapToIcon(item.group)}
              >
                <b>
                  {' '}
                  <FormattedMessage
                    id={mapToDescriptionId(item.group)}
                    defaultMessage='Legg til på hjemskjerm'
                  />
                </b>
              </IconAndTextItem>
            )}
            <SignalGroup
              key={item.group}
              icon={mapToIcon(item.group)}
              sensors={item.sensors}
            />
          </Fragment>
        ))}
      </div>
    );
  }

  return (
    <Text style={{ margin: '1rem' }}>
      <FormattedMessage
        id='sensorReadings.noMatch'
        values={{ vehicleSetId }}
        defaultMessage='Fant ingen sensorverdier for {vehicleSetId}'
      />
    </Text>
  );
};

export default SensorList;
