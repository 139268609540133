import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterId } from 'api/apiDataTypes';
import { Text } from 'shared/styles/texts';
import { getNoAlarmsMessage } from 'shared/components/ErrorMessages/ErrorMessages';

export const getEmptyListMessage = () => (
  <Text style={{ textAlign: 'center', marginTop: '2rem' }}>
    <FormattedMessage id='search.noHits' defaultMessage='Fant ingen treff' />
  </Text>
);

export const displayErrorMessage = (filters: FilterId[]) => {
  if (filters.length > 0) {
    return getNoAlarmsMessage();
  }

  return getEmptyListMessage();
};
