import React, { useEffect } from 'react';
import { FullPageLoader } from 'shared/components/Loading/Loading';
import Header from 'shared/components/Header/Header';
import loginFlow from 'auth/loginFlow';
import useLoginFlow from 'auth/useLoginFlow';
import { isDuringInitialization } from 'auth/loginFlowTypes';
import { useIntl } from 'react-intl';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import './App.css';

const App: React.FC = () => {
  const { state } = useLoginFlow(true);
  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'app.title',
      defaultMessage: 'Driftstjenester',
    });
  }, []);

  if (isDuringInitialization(state)) {
    return (
      <>
        <Header />
        <FullPageLoader />
      </>
    );
  }

  if (loginFlow.isAuthenticated()) return <AuthenticatedApp />;
  return <UnauthenticatedApp />;
};

export default App;
