import useLoginFlow, { useLoginFlowStatus } from 'auth/useLoginFlow';
import MenuContainer from 'features/Menu/MenuContainer/MenuContainer';
import SearchPage from 'pages/SearchPage/SearchPage';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Header from 'shared/components/Header/Header';
import { FlexContainer } from 'shared/components/Layout/Flex/Flex';
import styled from 'styled-components';

export const NavBar = styled(FlexContainer)`
  position: fixed;
  top: 0;
  z-index: 10;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  background-color: ${({ theme }): string => theme.headerColor};
  width: 100%;
  height: 54px;
  @media (max-width: ${({ theme }): string => theme.breakpointMobile}) {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }
`;

const Logout = (): null => {
  const { logout } = useLoginFlow();

  useEffect(() => {
    logout();
    window.location.href = '/';
  }, []);

  return null;
};

/**
 * The Header and MenuContainer is wrapped in a NavBar styled component,
 * to have it connected to each other, which was needed when the navbar was made to be fixed.
 */
const AuthenticatedApp: React.FC = () => {
  const { state, refreshToken, setExpiredAccessToken } = useLoginFlow();

  useEffect(() => {
    if (state.status === 'TokenRefreshing') {
      setExpiredAccessToken();
      refreshToken();
    }
  }, []);

  useLoginFlowStatus(state, (status) => {
    if (status === 'LoggedOut') {
      window.location.assign('/');
    }
  });

  return (
    <Router>
      <NavBar>
        <Header data-testid='header' />
        <MenuContainer />
      </NavBar>
      <Switch>
        <Route exact path='/' component={SearchPage} />
        <Route exact path='/login-redirect'>
          <Redirect to='/' />
        </Route>
        <Route exact path='/logout'>
          <Logout />
        </Route>
      </Switch>
    </Router>
  );
};

export default AuthenticatedApp;
