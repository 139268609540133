import React, { Dispatch, SetStateAction } from 'react';
import { StyledMenuButton, MenuButtonLine } from '../MenuStyles';

interface Props {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const MenuOpenCloseBtn: React.FC<Props> = (props: Props) => {
  const { isOpen, setOpen } = props;
  return (
    <StyledMenuButton
      isOpen={isOpen}
      onClick={(): void => {
        setOpen(!isOpen);
      }}
    >
      <MenuButtonLine isOpen={isOpen} />
      <MenuButtonLine isOpen={isOpen} />
      <MenuButtonLine isOpen={isOpen} />
    </StyledMenuButton>
  );
};

export default MenuOpenCloseBtn;
