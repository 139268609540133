import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'shared/styles/texts';

interface ErrorMessageProps {
  statusCode?: number;
  vehicleSetId: string;
  sensorType: string;
}

const VehicleErrorMessage: React.FC<ErrorMessageProps> = ({
  statusCode,
  vehicleSetId,
  sensorType,
}) => {
  if (statusCode === 404) {
    return (
      <Text style={{ textAlign: 'center', margin: '1rem 0' }}>
        <FormattedMessage
          id='sensorReadings.noMatch'
          values={{ vehicleSetId, sensorType }}
          defaultMessage='Fant ingen {sensorType} for {vehicleSetId}'
        />
      </Text>
    );
  }

  return (
    <Text style={{ textAlign: 'center', margin: '1rem 0' }}>
      <FormattedMessage
        id='sensorReadings.noData'
        values={{ vehicleSetId, sensorType }}
        defaultMessage='Kunne ikke hente {sensorType} for {vehicleSetId}'
      />
    </Text>
  );
};

export default VehicleErrorMessage;
