import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { getSearchQueryData, getSetTypeData } from 'api';
import { SearchResult } from 'api/apiDataTypes';
import SearchResults from 'features/SearchContent/ActivityAndVehicleSearchResults/SearchResults';
import { SensorAlarmFilter } from 'features/SensorAlarmFilter/SensorAlarmFilter';
import SetTypeFilter from 'features/SetTypeFilter/SetTypeFilter';
import { AlarmSearchResults } from 'features/SearchContent/AlarmSearchResults/AlarmSearchResults';
import { RecentSearches } from 'features/SearchContent/RecentSearches/RecentSearches';
import {
  addRecentSearch,
  getRecentSearches,
} from 'shared/utils/localStorageUtils';
import { InputField } from 'shared/components/VyInput/InputField';
import { VYClickDatePicker } from 'shared/components/Pickers/DatePicker/VYDatePicker';
import {
  SearchContainer,
  TimePaddingContainer,
  TopPaddedContainer,
  TopContentContainer,
  BottomPaddedContainer,
  BottomContentContainer,
  FilterButtonContainer,
} from './styles';
import { useSearchPageController } from './useSearchPageController';

const SearchPage: React.FC = () => {
  const intl = useIntl();
  const recentSearches = getRecentSearches();
  const {
    searchQuery,
    setSearchQuery,
    selectedDate,
    setSelectedDate,
    searchValue,
    setSearchValue,
    alarmFilters,
    setAlarmFilters,
    setTypeFilters,
    setSetTypeFilters,
    openDetails,
    vehicleSetId,
    arrivalTrainId,
    departureTrainId,
  } = useSearchPageController();

  const searchParams = {
    query: searchQuery,
    selectedDate,
    setTypes: setTypeFilters,
  };
  const searchData = useQuery<SearchResult>(
    ['searchData', searchParams],
    () => getSearchQueryData(searchParams),
    { enabled: searchQuery.length > 0 },
  );
  const setTypes = useQuery<string[]>(['setTypes', selectedDate], () =>
    getSetTypeData({ validAt: selectedDate }),
  );

  const triggerSearch = (value: string) => {
    setSearchQuery(value);
    setSearchValue(value);
    setAlarmFilters([]);
  };

  useEffect(() => {
    if (searchData.data && searchQuery) addRecentSearch(searchQuery);
  }, [searchData.data]);

  let searchResultsToShow;

  if (searchQuery) {
    searchResultsToShow = (
      <SearchResults
        data={searchData.data}
        openDetails={openDetails}
        selectedDate={selectedDate}
        alarmFilters={alarmFilters}
        loading={searchData.isLoading}
        vehicleSetId={vehicleSetId}
        arrivalTrainId={arrivalTrainId}
        departureTrainId={departureTrainId}
      />
    );
  } else if (alarmFilters.length > 0) {
    searchResultsToShow = (
      <AlarmSearchResults
        alarmFilters={alarmFilters}
        triggerSearch={triggerSearch}
      />
    );
  } else if (recentSearches && recentSearches.length) {
    searchResultsToShow = (
      <RecentSearches
        recentSearches={recentSearches}
        triggerSearch={triggerSearch}
      />
    );
  }

  const isActiveSearch = searchQuery !== '' || alarmFilters.length > 0;

  return (
    <SearchContainer
      data-testid='search-page-wrapper'
      isActiveSearch={isActiveSearch}
    >
      <TopPaddedContainer>
        <TopContentContainer>
          <InputField
            type='Search'
            onChange={(val) => {
              setSearchValue(val);
            }}
            placeholder={intl.formatMessage({
              id: 'search.placeholder',
              defaultMessage: 'Søk etter lokasjon, tognr eller kjøretøy',
            })}
            onEnter={(val) => {
              if (val) {
                setSearchQuery(val);
              }
            }}
            value={searchValue}
          />
          <TimePaddingContainer>
            <VYClickDatePicker
              selected={selectedDate}
              onChange={(date: Date) => setSelectedDate(date)}
            />
          </TimePaddingContainer>
          <FilterButtonContainer>
            <SensorAlarmFilter
              setAlarmFilters={setAlarmFilters}
              alarmFilters={alarmFilters}
            />
            <SetTypeFilter
              allSetTypes={setTypes.data || []}
              loading={setTypes.isLoading}
              error={setTypes.isError}
              setTypeFilters={setTypeFilters}
              setSetTypeFilters={setSetTypeFilters}
            />
          </FilterButtonContainer>
        </TopContentContainer>
      </TopPaddedContainer>

      {searchResultsToShow && (
        <BottomPaddedContainer>
          <BottomContentContainer isActiveSearch={isActiveSearch}>
            {searchResultsToShow}
          </BottomContentContainer>
        </BottomPaddedContainer>
      )}
    </SearchContainer>
  );
};

export default SearchPage;
