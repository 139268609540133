import { defaultTheme as PrimaryButton } from './PrimaryButton/theme';
import { defaultTheme as AdditionalButton } from './AdditionalButton/theme';
import { defaultTheme as SquareButton } from './SquareButton/theme';

const defaultTheme = {
  ...PrimaryButton,
  ...AdditionalButton,
  ...SquareButton,
};

export { defaultTheme };
