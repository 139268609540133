/* eslint-disable no-nested-ternary */
import { isAfter, parseISO, isSameMinute } from 'date-fns';
import { LocationActivity, VehicleSet, FilterId } from 'api/apiDataTypes';

/**
 * getSortTime returns a time for the vehicle set, which will be used
 * to determine which activities to display.
 * The time chosen is the departure time (if available), so that vehicle
 * sets are displayed until the vehicle set has left the station.
 */
const getSortTime = (activity: LocationActivity): string | undefined => {
  if (activity.actualDepartureTime) return activity.actualDepartureTime;
  if (activity.scheduledDepartureTime) return activity.scheduledDepartureTime;
  if (activity.activities && activity.activities[0]?.endTime)
    return activity.activities[0]?.endTime;
  if (activity.scheduledArrivalTime) return activity.scheduledArrivalTime;
  if (activity.actualArrivalTime) return activity.actualArrivalTime;
  if (activity.activities && activity.activities[0]?.startTime)
    return activity.activities[0]?.startTime;
  return undefined;
};

export const sortAndFilterActivities = (
  activities: LocationActivity[],
  selectedDate: Date,
): LocationActivity[] => {
  const compareActivities = (
    a: LocationActivity,
    b: LocationActivity,
  ): number => {
    const aTime = getSortTime(a);
    const bTime = getSortTime(b);
    if (aTime === undefined || bTime === undefined) return -1;
    return isAfter(parseISO(aTime), parseISO(bTime)) ? 1 : -1;
  };

  /**
   * filterActivities returns true if getSortTime(a) is at the same
   * minute, after selectedDate or undefined.
   *
   * The reason why is that we want
   * activities to be displayed until they are done/the vehicle
   * set is departed from the station.
   */
  const filterActivities = (a: LocationActivity): boolean => {
    const compareTime = getSortTime(a);
    if (compareTime === undefined) return true;
    if (isSameMinute(parseISO(compareTime), selectedDate)) return true;
    return isAfter(parseISO(compareTime), selectedDate);
  };

  return activities
    .filter((activity) => filterActivities(activity))
    .sort((a: LocationActivity, b: LocationActivity) =>
      compareActivities(a, b),
    );
};

export const filterActivitiesByAlarms = (
  list: LocationActivity[],
  alarms: FilterId[],
): LocationActivity[] => {
  // Filter activities by alarms.
  const isSelectedInFilter = (activity: LocationActivity) =>
    activity.activeSensorAlarms &&
    activity.activeSensorAlarms.some((r: FilterId) => alarms.includes(r));

  if (alarms.length > 0) {
    return list.filter(isSelectedInFilter);
  }

  return list;
};

/**
 * Returns the scheduled time and delay for the activity's given type.
 * If no scheduled time exists, null is returned. This is to ensure
 * that the displayed time always is based on scheduled time.
 * @param type - defines if the activity is arriving or departuring
 */
export const getTimeAndDelay = (
  locationActivity: LocationActivity,
  type: 'arrival' | 'departure',
): { time?: string; delay: number } => {
  let time;
  let delay: number;
  if (type === 'arrival') {
    if (locationActivity.scheduledArrivalTime) {
      time = locationActivity.scheduledArrivalTime;
    } else {
      time = undefined;
    }
    delay = locationActivity.arrivalDelay;
  } else {
    if (locationActivity.scheduledDepartureTime) {
      time = locationActivity.scheduledDepartureTime;
    } else {
      time = undefined;
    }
    delay = locationActivity.departureDelay;
  }

  return { time, delay };
};

export const getActivityDetail = (
  data: LocationActivity[],
  vehicleSetId: string | null,
  arrivalTrainId: string | null,
  departureTrainId: string | null,
): LocationActivity | undefined => {
  const activity = data.find((t) =>
    arrivalTrainId
      ? t.vehicleSetId === vehicleSetId && t.arrivalTrainId === arrivalTrainId
      : t.vehicleSetId === vehicleSetId &&
        t.departureTrainId === departureTrainId,
  );

  const standbyActivity = data.find(
    (t) =>
      t.vehicleSetId === vehicleSetId &&
      t.arrivalTrainId === null &&
      t.departureTrainId === null,
  );

  return activity ? activity : standbyActivity;
};

export const getVehicleSetDetail = (
  data: VehicleSet[],
  vehicleSetId: string | null,
): VehicleSet | undefined => {
  return data.find(
    (t) =>
      vehicleSetId &&
      t.vehicleSetId === vehicleSetId &&
      t.hasActivities === 'NO',
  );
};

export const getUniqueKey = (locationActivity: LocationActivity): string => {
  return (
    locationActivity.arrivalTrainId +
    locationActivity.arrivalNominalDate +
    locationActivity.departureTrainId +
    locationActivity.departureNominalDate +
    locationActivity.location +
    locationActivity.vehicleSetId
  );
};
