import axios, { AxiosResponse } from 'axios';
import pkceChallenge from 'pkce-challenge';
import * as uuid from 'uuid';
import {
  CognitoResponse,
  LoginFlowState,
  LoginFlowStatus,
  LoginSettings,
} from './loginFlowTypes';
import { getLoginData } from '../api';
import { getState, onStateChange, setState } from './storage';

import { registerInterceptor } from './refreshTokenInterceptor';
import { verifyThatTokenIsStillValid } from './tokenValidation';

const REDIRECT_URL = `${window.location.origin}/login-redirect`;

registerInterceptor();

const loggedInStates: LoginFlowStatus[] = [
  'LoggedIn',
  'AccessTokenExpired',
  'TokenRefreshing',
];
const isAuthenticated = (): boolean =>
  loggedInStates.includes(getState().status);

interface LoginUrlParameters {
  authUrl: string;
  clientId: string;
  pkceChallengeCode: string;
  redirectUrl: string;
  cognitoState: string;
}

const getLoginUrl = ({
  authUrl,
  clientId,
  pkceChallengeCode,
  redirectUrl,
  cognitoState,
}: LoginUrlParameters): string =>
  `${authUrl}/oauth2/authorize?response_type=code&client_id=${clientId}&code_challenge=${pkceChallengeCode}&redirect_uri=${redirectUrl}&code_challenge_method=S256&state=${cognitoState}`;

// Steps
const resetState = (): LoginFlowState => {
  return setState({ status: 'LoggedOut' });
};

const fail = (reason: string): void => {
  setState({
    status: 'Failure',
    reason,
  });
};

const failWithIllegalTransition = (
  from: LoginFlowStatus,
  to: LoginFlowStatus,
): void => fail(`Attempted illegal status transition: ${from} -> ${to}`);

const fetchSettings = (override?: LoginSettings): Promise<LoginFlowState> => {
  return new Promise<LoginFlowState>((resolve, reject) => {
    setState({
      status: 'LoginSettingsLoading',
    });

    if (override == null) {
      getLoginData()
        .then((response: AxiosResponse<LoginSettings>) => {
          resolve(
            setState({
              status: 'LoginSettingsSuccess',
              settings: response.data,
            }),
          );
        })
        .catch((caughtError) => {
          fail(caughtError);
        });
    } else {
      resolve(
        setState({
          status: 'LoginSettingsSuccess',
          settings: override,
        }),
      );
    }
  });
};

const login = (): void => {
  const state = getState();
  if (state.status === 'LoginSettingsSuccess') {
    const xsrfToken = uuid.v4();
    const pkceKeys = pkceChallenge();

    setState({
      status: 'PkceCodeLoading',
      settings: state.settings,
      xsrfToken,
      pkceVerifier: pkceKeys.code_verifier,
    });

    window.location.assign(
      getLoginUrl({
        authUrl: state.settings.authUrl,
        clientId: state.settings.clientId,
        cognitoState: xsrfToken,
        pkceChallengeCode: pkceKeys.code_challenge,
        redirectUrl: REDIRECT_URL,
      }),
    );
  }
};

const verifyPkceCode = (): void => {
  const state = getState();
  if (state.status === 'PkceCodeLoading') {
    const pkceCode = new URLSearchParams(window.location.search).get('code');

    if (pkceCode != null) {
      setState({
        status: 'PkceCodeSuccess',
        settings: state.settings,
        pkceVerifier: state.pkceVerifier,
        pkceCode,
        xsrfToken: state.xsrfToken,
      });
    } else {
      fail('Pkce code not found');
    }
  } else {
    failWithIllegalTransition(state.status, 'PkceCodeSuccess');
  }
};

const fetchToken = (): Promise<LoginFlowState> => {
  const state = getState();

  return new Promise<LoginFlowState>((resolve, reject) => {
    if (state.status === 'PkceCodeSuccess') {
      setState({
        status: 'TokenLoading',
        pkceCode: state.pkceCode,
        settings: state.settings,
      });
      axios
        .request<any, AxiosResponse<CognitoResponse>>({
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `${state.settings.authUrl}/oauth2/token`,
          data: `code=${state.pkceCode}&code_verifier=${state.pkceVerifier}&redirect_uri=${REDIRECT_URL}&client_id=${state.settings.clientId}&grant_type=authorization_code`,
        })
        .then((response) => {
          const { access_token: accessToken, refresh_token: refreshToken } =
            response.data;

          resolve(
            setState({
              status: 'LoggedIn',
              accessToken,
              refreshToken,
              settings: state.settings,
            }),
          );
        })
        .catch(() => {
          fail('Error while fetching token');
        });
    } else {
      reject(getState());
      failWithIllegalTransition(state.status, 'PkceCodeSuccess');
    }
  });
};

const setExpiredAccessToken = (): void => {
  const state = getState();

  if (state.status === 'LoggedIn' || state.status === 'TokenRefreshing') {
    setState({
      status: 'AccessTokenExpired',
      settings: state.settings,
      refreshToken: state.refreshToken,
    });
  } else {
    failWithIllegalTransition(state.status, 'AccessTokenExpired');
  }
};

const refreshToken = (duringInitalization = false): Promise<LoginFlowState> => {
  const state = getState();

  return new Promise<LoginFlowState>((resolve, reject) => {
    if (
      state.status === 'AccessTokenExpired' ||
      state.status === 'Initialization:AccessTokenExpired'
    ) {
      setState({
        status: duringInitalization
          ? 'Initialization:TokenRefreshing'
          : 'TokenRefreshing',
        settings: state.settings,
        refreshToken: state.refreshToken,
      });

      axios
        .request<any, AxiosResponse<CognitoResponse>>({
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `${state.settings.authUrl}/oauth2/token`,
          data: `client_id=${state.settings.clientId}&grant_type=refresh_token&refresh_token=${state.refreshToken}`,
        })
        .then((response) => {
          const { access_token: accessToken } = response.data;

          resolve(
            setState({
              status: 'LoggedIn',
              accessToken,
              refreshToken: state.refreshToken,
              settings: state.settings,
            }),
          );
        })
        .catch(() => {
          logout();
          reject(getState());
        });
    } else {
      reject(getState());
      failWithIllegalTransition(state.status, 'TokenRefreshing');
    }
  });
};

const logout = (): LoginFlowState => {
  return setState({ status: 'LoggedOut' });
};

const validateToken = async () => {
  const state = getState();

  if (state.status === 'LoggedIn') {
    setState({
      status: 'Initialization:ValidatingToken',
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      settings: state.settings,
    });

    try {
      await verifyThatTokenIsStillValid(state.accessToken);
      setState({
        status: 'LoggedIn',
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        settings: state.settings,
      });
    } catch {
      setState({
        status: 'Initialization:AccessTokenExpired',
        refreshToken: state.refreshToken,
        settings: state.settings,
      });
      // TODO - dette må håndteres bedre
      refreshToken(true).catch(() => {});
    }
  }
};

export default {
  getState,
  setState,
  resetState,
  fetchSettings,
  login,
  verifyPkceCode,
  fetchToken,
  setExpiredAccessToken,
  refreshToken,
  onStateChange,
  isAuthenticated,
  logout,
  validateToken,
};
