import axios from 'axios';
import requestConfig from './api';
import { HistoryData } from './apiDataTypes';
import apiUrl from './apiUrl';

async function getSensorHistoryForGroup(params: {
  vehicleSetId: string;
  sensorGroup: string;
  timestamp?: number;
}): Promise<HistoryData> {
  const { vehicleSetId, sensorGroup, timestamp } = params;

  return axios
    .get(
      `${apiUrl()}/vehicle/${encodeURIComponent(
        vehicleSetId,
      )}/history/${encodeURIComponent(sensorGroup)}`,
      {
        ...requestConfig(),
        params: {
          timestamp,
        },
      },
    )
    .then((res) => res.data);
}
export default getSensorHistoryForGroup;
