import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { VehicleSet } from 'api/apiDataTypes';
import { SubText, Text } from 'shared/styles/texts';
import { BackButton } from 'shared/components/BackButton/BackButton';
import VehicleInformation from '../VehicleInformation/VehicleInformation';
import {
  SearchDetailsContainer,
  BackButtonContainer,
  SearchDetailsWrapper,
} from './styles';

interface TrainElementNoActivityProps {
  data: VehicleSet;
  onClickOpen: () => void;
  onClickBack: () => void;
  isOpen: boolean;
  vehicleSetId: string | null;
}

export const VehicleSetDetails = ({
  data,
  onClickOpen,
  onClickBack,
  isOpen,
  vehicleSetId,
}: TrainElementNoActivityProps) => {
  const [isAlert, setIsAlert] = useState(false);
  const notifyAlert = (): void => {
    setIsAlert(true);
  };
  const intl = useIntl();

  return (
    <SearchDetailsWrapper
      onClick={() => {
        if (!isOpen) {
          onClickOpen();
        }
      }}
    >
      {isOpen && (
        <BackButtonContainer>
          <BackButton onClick={() => onClickBack()}>
            {intl.formatMessage({
              id: 'details.back',
              defaultMessage: 'Tilbake',
            })}
          </BackButton>
        </BackButtonContainer>
      )}

      <SearchDetailsContainer isOpen={isOpen}>
        <SubText>{data.vehicleSetId}</SubText>
        <Text>
          <FormattedMessage
            id='search.noPlannedActivities'
            defaultMessage='Ingen planlagte aktiviteter.'
          />
        </Text>
        {!vehicleSetId && (
          <Text style={{ textDecoration: 'underline' }}>
            <FormattedMessage
              id='details.showSensorData'
              defaultMessage='Vis sensordata'
            />
          </Text>
        )}
      </SearchDetailsContainer>

      {isOpen && (
        <SearchDetailsContainer
          isOpen={isOpen}
          style={{ padding: '0px', rowGap: '0px' }}
        >
          <VehicleInformation
            vehicleSetId={data.vehicleSetId}
            notifyAlert={notifyAlert}
          />
        </SearchDetailsContainer>
      )}
    </SearchDetailsWrapper>
  );
};
