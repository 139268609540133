import axios from 'axios';
import requestConfig from './api';
import { HistoryData } from './apiDataTypes';
import apiUrl from './apiUrl';

async function getSensorHistory(params: {
  vehicleSetId: string;
  timestamp?: number;
}): Promise<HistoryData> {
  const { vehicleSetId, timestamp } = params;

  return axios
    .get(`${apiUrl()}/vehicle/${encodeURIComponent(vehicleSetId)}/history`, {
      ...requestConfig(),
      params: {
        timestamp,
      },
    })
    .then((res) => res.data);
}
export default getSensorHistory;
