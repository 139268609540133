import { SensorGroup } from 'api/apiDataTypes';

export enum GroupNameToIcon {
  water = 'water',
  septic = 'toilet',
  batteryVoltage = 'battery',
  outdoorTemperature = 'temp',
  indoorTemperature = 'temp',
  batteryChargerVoltage = 'battery',
}

export enum UnitMax {
  percent = 100,
  volt = 140,
  degreeInside = 30,
  degreeOutside = 40,
}

export enum UnitMin {
  percent = 0,
  volt = 90,
  degreeInside = 10,
  degreeOutside = -30,
}

export const returnUnitMaxValue = (unit: string, groupName: string) => {
  if (unit === '°C' && groupName === 'indoorTemperature') {
    return UnitMax.degreeInside;
  }
  if (unit === '°C' && groupName === 'outdoorTemperature') {
    return UnitMax.degreeOutside;
  }
  if (unit === 'V') {
    return UnitMax.volt;
  }
  return UnitMax.percent;
};

export const returnIconName = (groupName: string) => {
  if (groupName === 'water') {
    return GroupNameToIcon.water;
  }
  if (groupName === 'septic') {
    return GroupNameToIcon.septic;
  }
  if (groupName === 'indoorTemperature') {
    return GroupNameToIcon.indoorTemperature;
  }
  if (groupName === 'outdoorTemperature') {
    return GroupNameToIcon.outdoorTemperature;
  }
  if (groupName === 'batteryVoltage') {
    return GroupNameToIcon.batteryVoltage;
  }
  return GroupNameToIcon.batteryChargerVoltage;
};

export const mapToDescriptionId = (groupName: string): string => {
  if (groupName === 'water') {
    return 'sensorReadings.waterTank';
  }
  if (groupName === 'septic') {
    return 'sensorReadings.septicTank';
  }
  if (groupName === 'indoorTemperature') {
    return 'sensorReadings.indoor.temp';
  }
  if (groupName === 'outdoorTemperature') {
    return 'sensorReadings.outdoor.temp';
  }
  if (groupName === 'batteryVoltage') {
    return 'sensorReadings.battery.voltage';
  }
  return 'sensorReadings.batteryCharger.voltage';
};

export const returnUnitMinValue = (unit: string, groupName: string) => {
  if (unit === '°C' && groupName === 'indoorTemperature') {
    return UnitMin.degreeInside;
  }
  if (unit === '°C' && groupName === 'outdoorTemperature') {
    return UnitMin.degreeOutside;
  }
  if (unit === 'V') {
    return UnitMin.volt;
  }
  return UnitMin.percent;
};

export const returnSensorGroupType = (g: string): SensorGroup => {
  if (g === 'water') {
    return SensorGroup.water;
  }
  if (g === 'septic') {
    return SensorGroup.septic;
  }
  if (g === 'indoorTemperature') {
    return SensorGroup.indoorTemperature;
  }
  if (g === 'outdoorTemperature') {
    return SensorGroup.outdoorTemperature;
  }
  if (g === 'batteryVoltage') {
    return SensorGroup.batteryVoltage;
  }
  return SensorGroup.batteryChargerVoltage;
};

export const returnColorType = (vehicleType: string, theme: any) => {
  if (vehicleType === 'BPa') {
    return theme.BPaGraphLineColor;
  }
  if (vehicleType === 'BCMU') {
    return theme.BCMUGraphLineColor;
  }
  if (vehicleType === 'CabA') {
    return theme.CabAGraphLineColor;
  }
  if (vehicleType === 'CabB') {
    return theme.CabBGraphLineColor;
  }
  if (vehicleType === 'BMa') {
    return theme.BMaGraphLineColor;
  }
  if (vehicleType === 'BPb') {
    return theme.BPbGraphLineColor;
  }
  return theme.BMbGraphLineColor;
};

export const returnDashArray = (vehicleType: string) => {
  if (vehicleType === 'BPa') {
    return '0';
  }
  if (vehicleType === 'BCMU') {
    return '10,1 ';
  }
  if (vehicleType === 'CabA') {
    return '2, 2';
  }
  if (vehicleType === 'CabB') {
    return '5, 2';
  }
  if (vehicleType === 'BMa') {
    return '7, 3';
  }
  if (vehicleType === 'BPb') {
    return '2, 6';
  }
  return '5, 5';
};
