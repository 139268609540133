import styled from 'styled-components';
import React from 'react';
import { ReactComponent as VyLogoImage } from '../../images/vy.logo.final_primary.svg';

const VyLogoDiv = styled(VyLogoImage)`
  z-index: 99;
  align-self: left;
  width: 5em;
  cursor: pointer;
  position: absolute;
`;

type LogoProps = {
  onClickFunction: () => void;
};

const VyLogo: React.FC<LogoProps> = ({ onClickFunction }: LogoProps) => {
  return (
    <VyLogoDiv data-testid='logo-vy' title='VyLogo' onClick={onClickFunction} />
  );
};

export default VyLogo;
