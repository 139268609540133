import React, { RefObject, useContext, useEffect } from 'react';
import useWindowDimensions from 'shared/hooks/useWindowDimensions';
import { isWideScreen } from 'shared/utils/utils';
import { ThemeContext } from 'styled-components';
import { drawGraph } from './DrawGraph';
import { GraphProps } from './GraphTypes';

export const Graph: React.FC<GraphProps> = ({
  historyData,
  setWarning,
  timePeriod,
}) => {
  const vizRef: RefObject<HTMLDivElement> = React.createRef();
  const theme = useContext(ThemeContext);
  const wideGraph = isWideScreen(useWindowDimensions().width);

  useEffect(() => {
    drawGraph({
      vizRef,
      historyData,
      setWarning,
      timePeriod,
      theme,
      wideGraph,
    });
  }, [historyData, wideGraph]);

  return <div id='my_dataviz' ref={vizRef} />;
};
