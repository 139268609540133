import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FlexContainer, FlexItem } from 'shared/components/Layout/Flex/Flex';
import { StyledLink, StyledMenu } from '../MenuStyles';
import { removeRecentSearches } from '../../../shared/utils/localStorageUtils';
import { LanguageSelector } from '../../../shared/components/LanguageSelector/LanguageSelector';
import { AppIcon } from '../../../shared/components/Icons/Icons';

interface MenuItemsProps {
  isOpen: boolean;
}

interface DividerProps {
  margin?: string;
}

export const Divider = styled.hr`
  width: 100%;
  border-width: 0;
  color: ${({ theme }): string => theme.title};
  background-color: ${({ theme }): string => theme.title};
  height: 2px;
  margin: ${(props: DividerProps): string =>
    props.margin ? props.margin : 'auto'};
`;

const sapClientUrl =
  'https://onboard.nsb.no/neptune/zui_nad_pm_notification_list_p.html?sap-client=001';

const MenuItems: React.FC<MenuItemsProps> = (props: MenuItemsProps) => {
  const { isOpen } = props;
  return (
    <StyledMenu isOpen={isOpen}>
      <FlexContainer margin='0'>
        <FlexItem margin='1.5rem 0 0 0'>
          <StyledLink
            href={sapClientUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <AppIcon type='launch' invert='invert(1)' marginRight='13px' />
            <FormattedMessage
              id='details.goToApp'
              defaultMessage='Gå til app'
            />
          </StyledLink>
        </FlexItem>
        <FlexItem margin='1.5rem 0 0 0'>
          <AppIcon type='feedback' invert='invert(1)' marginRight='13px' />
          <StyledLink
            target='_blank'
            rel='noopener noreferrer'
            href='mailto:team-kjoretoy@vy.no'
          >
            <FormattedMessage
              id='menu.giveFeedback'
              defaultMessage='Give feedback'
            />
          </StyledLink>
        </FlexItem>
        <Divider margin='2rem 0 0 0' />
        <FlexItem margin='1.5rem 0 0 0'>
          <b>
            <FormattedMessage
              id='menu.changeLanguage'
              defaultMessage='Change language'
            />
          </b>
          <LanguageSelector />
        </FlexItem>
        <Divider margin='1rem 0 0 0' />
        <FlexItem margin='1.5rem 0 0 0'>
          <StyledLink
            href='#'
            onClick={(): void => {
              removeRecentSearches();
            }}
          >
            <FormattedMessage
              id='menu.clearRecentSearches'
              defaultMessage='Clear recent searches'
            />
          </StyledLink>
        </FlexItem>
        <FlexItem margin='1.5rem 0 0 0'>
          <StyledLink href='/logout'>
            <FormattedMessage id='menu.logout' defaultMessage='Logg ut' />
          </StyledLink>
        </FlexItem>
      </FlexContainer>
    </StyledMenu>
  );
};

export default MenuItems;
