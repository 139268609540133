import React from 'react';
import styled from 'styled-components';
import arrow, { ReactComponent as Arrow } from '../../images/arrow.svg';
import clean from '../../images/clean.svg';
import cogwheel from '../../images/cogwheel.svg';
import clock from '../../images/clock.svg';
import diesel from '../../images/diesel.svg';
import envelope from '../../images/envelope.svg';
import exclamation from '../../images/exclamation.svg';
import battery from '../../images/battery.svg';
import temp from '../../images/temp.svg';
import hammerWrench from '../../images/hammerwrench.svg';
import history from '../../images/history.svg';
import hourglass from '../../images/hourglass.svg';
import launch from '../../images/launch.svg';
import question from '../../images/questionmark.svg';
import search from '../../images/search.svg';
import standby from '../../images/standby.svg';
import toilet from '../../images/toilet.svg';
import water from '../../images/water.svg';
import feedback from '../../images/feedback.svg';
import position from '../../images/position.svg';
import alert, { ReactComponent as Alert } from '../../images/alert.svg';

type Icon = string;

const icons: Record<string, Icon> = {
  alert,
  arrow,
  battery,
  cleaning: clean,
  cogwheel,
  clock,
  refueling: diesel,
  envelope,
  hourglass,
  hammerWrench,
  history,
  info: exclamation,
  launch,
  question,
  search,
  standby,
  temp,
  toilet,
  warn: exclamation,
  water,
  feedback,
  position,
};

export const getIcon = (type: string): Icon => {
  return icons[type];
};

interface AppIconProps {
  type: string;
  invert?: string;
  marginRight?: string;
  marginLeft?: string;
  height?: string;
  width?: string;
}

/**
 * The purpose of the invert parameter is to easily change between black and white icons
 */
export const AppIcon: React.FC<AppIconProps> = (props: AppIconProps) => {
  const { type, invert, marginRight, marginLeft, height, width } = props;
  return (
    <img
      style={{
        marginRight,
        marginLeft,
        maxHeight: '1.3em',
        height,
        width,
        maxWidth: '1.3em',
        filter: invert, // Invert between black and white
      }}
      src={getIcon(type)}
      alt=''
    />
  );
};

export const WhiteSideArrowSVG = styled(Arrow)`
  height: 0.6em;
  transform: rotate(270deg);
  fill: white;
`;

interface AlertIconProps {
  marginTop?: string;
  marginRight?: string;
}

const AlertSVG = styled(Alert)`
  fill: ${({ theme }): string => `${theme.errorColor}`};
`;

export const AlertIcon = (props: AlertIconProps): JSX.Element => {
  const { marginTop, marginRight } = props;
  return (
    <AlertSVG
      style={{
        marginRight,
        marginTop,
        maxHeight: '1.3em',
        maxWidth: '1.3em',
      }}
    />
  );
};
