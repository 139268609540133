/**
 * Internal dependencies
 */
import { defaultTheme as Button } from 'shared/components/Button/theme';
import { defaultTheme as Banner } from 'shared/components/Banner/theme';
import { defaultTheme as Checkbox } from 'shared/components/Checkbox/theme';
import { defaultTheme as SliderSwitch } from 'shared/components/SliderSwitch/theme';
import { defaultTheme as VyInput } from 'shared/components/VyInput/theme';
import { defaultTheme as Layout } from 'shared/components/Layout/theme';
import { defaultTheme as SensorAlarmFilter } from 'features/SensorAlarmFilter/theme';
import { defaultTheme as StatusMessages } from 'features/SearchContent/ActivityAndVehicleSearchResults/StatusMessage/theme';
import { defaultTheme as Menu } from 'features/Menu/theme';
import { defaultTheme as Graph } from 'features/SearchContent/ActivityAndVehicleSearchResults/Graph/theme';

import * as colors from '../../colors';

import breakpoints from '../../breakpoints';
import widths from '../../widths';
import base from './base';
import shared from './shared';

const defaultTheme = {
  // Global
  ...base,
  ...breakpoints,
  ...widths,

  // Context
  ...shared,

  ...Button,
  ...Banner,
  ...SliderSwitch,
  ...VyInput,
  ...Layout,
  ...Checkbox,
  ...Menu,
  ...StatusMessages,
  ...SensorAlarmFilter,
  ...Graph,

  // Remove at some point when cleaning up buttons.
  detailItemBackground: colors.backgroundWhite,
};

export default defaultTheme;
