import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from 'shared/components/Layout/Flex/Flex';
import VyLogo from '../VyLogo/VyLogo';
import InstallBanner from '../Banner/InstallBanner/InstallBanner';

const HeaderContainer = styled(FlexContainer)`
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  background-color: ${({ theme }): string => theme.headerColor};
  width: 100%;
  height: 54px;

  @media (max-width: ${({ theme }): string => theme.breakpointMobile}) {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }
`;

const Header: React.FC = () => {
  const clickFunction = (): void => {
    window.location.assign('/');
  };

  return (
    <>
      <HeaderContainer>
        <VyLogo onClickFunction={clickFunction} />
      </HeaderContainer>
      {/* InstallBanner is placed here to catch the beforeinstallprompt, since the header is always present */}
      <InstallBanner />
    </>
  );
};

export default Header;
