import axios from 'axios';
import requestConfig from './api';
import apiUrl from './apiUrl';
import { SensorData, SensorGroup } from './apiDataTypes';

async function getSensorData(params: {
  sensors: SensorGroup[];
}): Promise<SensorData[]> {
  const { sensors } = params;
  const config = requestConfig();

  const sensorStrings = sensors.map((sensor) => SensorGroup[sensor]);

  const query =
    sensorStrings.length > 0 ? `?signalGroups=${sensorStrings.join(',')}` : '';

  return axios
    .get(`${apiUrl()}/diagnostics/${query}`, config)
    .then((res) => res.data);
}

export default getSensorData;
