import styled from 'styled-components';

type Props = {
  direction?: string;
  width?: string;
  maxWidth?: string;
  grow?: string;
  height?: string;
  alignItems?: string;
  justifyContent?: string;
  margin?: string;
  wrap?: string;
  alignSelf?: string;
  textAlign?: string;
  minWidth?: string;
};

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: Props): string =>
    props.direction ? props.direction : 'column'};
  width: ${(props: Props): string => (props.width ? props.width : 'auto')};
  height: ${(props: Props): string => (props.height ? props.height : 'auto')};
  margin: ${(props: Props): string => (props.margin ? props.margin : 'auto')};
  align-items: ${(props: Props): string =>
    props.alignItems ? props.alignItems : 'normal'};
  justify-content: ${(props: Props): string =>
    props.justifyContent ? props.justifyContent : 'normal'};
  flex-wrap: ${(props: Props): string => (props.wrap ? props.wrap : 'wrap')};
  text-align: ${(props: Props): string =>
    props.textAlign ? props.textAlign : 'left'};
`;

export const FlexItem = styled.div`
  flex-grow: ${(props: Props): string => (props.grow ? props.grow : '1')};
  width: ${(props: Props): string => (props.width ? props.width : 'auto')};
  align-self: ${(props: Props): string =>
    props.alignSelf ? props.alignSelf : 'auto'};
  margin: ${(props: Props): string => (props.margin ? props.margin : 'auto')};
  max-width: ${(props: Props): string =>
    props.maxWidth ? props.maxWidth : 'auto'};
  text-align: ${(props: Props): string =>
    props.textAlign ? props.textAlign : 'left'};
  min-width: ${(props: Props): string =>
    props.minWidth ? props.minWidth : 'auto'};
`;

export const StickyFlexItem = styled(FlexItem)`
  background-color: ${({ theme }): string => theme.StickyFlexItemBackground};
`;
