import * as colors from 'shared/styles/colors';

export const defaultTheme = {
  BPaGraphLineColor: colors.lightBlueContrast,
  BCMUGraphLineColor: colors.darkGreen80,
  BMbGraphLineColor: colors.orange140,
  CabAGraphLineColor: colors.pink120,
  CabBGraphLineColor: colors.darkBlueContrast,
  BMaGraphLineColor: colors.blueGrey80,
  BPbGraphLineColor: colors.darkGreen,

  thresholdColor: colors.errorRed20,
  tooltipColor: colors.darkGrey,
};
