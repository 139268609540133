import styled from 'styled-components';

type Props = {
  isOpen: boolean;
};

export const StyledMenuButton = styled.button`
  position: ${(props: Props): string => (props.isOpen ? 'fixed' : 'absolute')};
  top: 12px;
  right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 50;

  &:focus {
    outline: none;
  }
`;

export const MenuButtonLine = styled.div`
  width: 1.6rem;
  height: 0.2rem;
  background: ${({ theme, isOpen }): string =>
    isOpen ? theme.menuButtonOpenedBackground : theme.menuButtonBackground};
  border-radius: 3px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;

  :first-child {
    transform: ${(props: Props): string =>
      props.isOpen ? 'rotate(45deg)' : 'rotate(0)'};
  }

  :nth-child(2) {
    opacity: ${(props: Props): string => (props.isOpen ? '0' : '1')};
    transform: ${(props: Props): string =>
      props.isOpen ? 'translateX(20px)' : 'translateX(0)'};
    background: ${({ theme }): string => theme.menuButtonMidBackground};
  }

  :nth-child(3) {
    transform: ${(props: Props): string =>
      props.isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }): string => theme.menuBackground};
  height: 100vh;
  z-index: 49;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${(props: Props): string =>
    props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
  color: ${({ theme }): string => theme.menuTextColor};

  @media (max-width: ${({ theme }): string => theme.breakpointMobile}) {
    width: 100%;
    height: 100%;
    min-height: 350px;
  }
`;

export const StyledLink = styled.a`
  font-size: 18px;
  color: ${({ theme }): string => theme.menuTextColor};
  text-decoration: none;
  text-underline-position: under;

  :focus,
  :hover {
    color: ${({ theme }): string => theme.btnSecondaryVariantBackgroundHover};
  }

  @media (max-width: ${({ theme }): string => theme.breakpointMobile}) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }): string => theme.widthGlobal};
  margin: 0 auto;
`;
