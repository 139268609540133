import React, { useState, useRef, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useBackdropContext } from 'app/AppProvider';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import filter from 'shared/images/filter.svg';
import close from 'shared/images/close.svg';
import { Text } from 'shared/styles/texts';
import { AdditionalButtonExtraSmall } from 'shared/components/Button/AdditionalButton/AdditionalButton';
import { PrimaryButtonSmall } from 'shared/components/Button/PrimaryButton/PrimaryButton';
import {
  OuterContainer,
  FilterContainer,
  TitleContainer,
  CloseIcon,
  ButtonContainer,
} from './FilterButtonStyles';

interface ButtonProps {
  selectedFiltersLength: number;
}

const Button = styled(AdditionalButtonExtraSmall)<ButtonProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.selectedFiltersLength > 0
      ? props.theme.sensorAlarmFilterActive
      : props.theme.sensorAlarmFilterBackground};
`;

const ButtonText = styled.span`
  white-space: nowrap;
`;

const Icon = styled.img`
  margin-right: 5px;
  width: 18px;
  height: 18px;
`;

interface FilterButtonProps {
  selectedFilters: any[];
  onSelect: () => void;
  buttonActiveTextId: string;
  buttonInactiveTextId: string;
  titleTextId: string;
  children: ReactNode;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  selectedFilters,
  onSelect,
  buttonActiveTextId,
  buttonInactiveTextId,
  titleTextId,
  children,
}) => {
  const [openFilter, setOpenFilter] = useState(false);

  const { hideBackdrop, showBackdrop } = useBackdropContext();

  const clickRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  useOnClickOutside(clickRef, (e: any) => {
    if (
      filterButtonRef.current !== null &&
      !filterButtonRef.current.contains(e.target)
    ) {
      onClose();
    }
  });

  const handleFilterButtonClick = () => {
    if (openFilter) {
      onSelect();
    } else {
      showBackdrop();
    }
    setOpenFilter(!openFilter);
  };

  const onClose = () => {
    hideBackdrop();
    setOpenFilter(false);
    onSelect();
  };

  return (
    <OuterContainer>
      <Button
        ref={filterButtonRef}
        selectedFiltersLength={selectedFilters.length}
        onClick={handleFilterButtonClick}
      >
        <Icon src={filter} alt='Filter' />
        <ButtonText>
          {selectedFilters.length > 0 ? (
            <>
              {`${selectedFilters.length} `}
              <FormattedMessage
                id={buttonActiveTextId}
                defaultMessage='filter valgt'
              />
            </>
          ) : (
            <FormattedMessage
              id={buttonInactiveTextId}
              defaultMessage='Sensordata'
            />
          )}
        </ButtonText>
      </Button>
      {openFilter && (
        <FilterContainer ref={clickRef}>
          <TitleContainer>
            <Text bold>
              <FormattedMessage
                id={titleTextId}
                defaultMessage='Se sensorer som har passert grenseverdi'
              />
            </Text>
            <CloseIcon onClick={onClose}>
              <img src={close} alt='close' />
            </CloseIcon>
          </TitleContainer>
          {children}
          <ButtonContainer>
            <PrimaryButtonSmall onClick={onClose}>
              <FormattedMessage
                id='sensorAlarmFilter.save'
                defaultMessage='Lagre'
              />
            </PrimaryButtonSmall>
          </ButtonContainer>
        </FilterContainer>
      )}
    </OuterContainer>
  );
};
