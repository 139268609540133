import React from 'react';
import styled from 'styled-components';
import { DefaultDataLoadingContextProvider } from 'shared/context/DataLoadingContext';
import { useHistory } from 'react-router-dom';
import { LocationActivity, FilterId, SearchResult } from 'api/apiDataTypes';
import { OpenDetailsCallback } from 'pages/SearchPage/useSearchPageController';
import {
  getUniqueKey,
  sortAndFilterActivities,
  filterActivitiesByAlarms,
  getActivityDetail,
  getVehicleSetDetail,
} from './searchResultsListUtils';
import { displayErrorMessage, getEmptyListMessage } from './ErrorMessages';
import ActivityList from './ActivityList';
import VehicleSetList from './VehicleSetList';
import { ActivityDetails } from '../SearchResultsDetails/ActivityDetails';
import { VehicleSetDetails } from '../SearchResultsDetails/VehicleSetDetails';

interface SearchResultsProps {
  searchResult: SearchResult;
  openDetails: OpenDetailsCallback;
  selectedDate: Date;
  alarmFilters: FilterId[];
  vehicleSetId: string | null;
  arrivalTrainId: string | null;
  departureTrainId: string | null;
}

const SearchResultsContainer = styled.div`
  & > *:first-child {
    margin-top: 2rem;
  }
`;

const SearchResultsList: React.FC<SearchResultsProps> = ({
  searchResult,
  openDetails,
  selectedDate,
  alarmFilters,
  vehicleSetId,
  arrivalTrainId,
  departureTrainId,
}) => {
  const history = useHistory();

  const { activities, vehicleSets } = searchResult;

  // Sort and filter activities by time
  const sortedAndFilteredActivities: LocationActivity[] = activities.length
    ? sortAndFilterActivities(activities, selectedDate)
    : [];

  // Filter activities by alarms.
  const alarmFilteredActivities: LocationActivity[] = activities.length
    ? filterActivitiesByAlarms(sortedAndFilteredActivities, alarmFilters)
    : [];

  const showActivityDetail = activities.length && vehicleSetId;

  const showVehicleSetDetail = vehicleSets.length && vehicleSetId;

  const getViewContent = () => {
    if (showActivityDetail) {
      const activityDetail = getActivityDetail(
        searchResult.activities,
        vehicleSetId,
        arrivalTrainId,
        departureTrainId,
      );

      return activityDetail ? (
        <ActivityDetails
          isOpen
          key={getUniqueKey(activityDetail)}
          selectedDate={selectedDate}
          data={activityDetail}
          onClickBack={() => {
            history.goBack();
          }}
          onClickOpen={() => {}}
        />
      ) : (
        getEmptyListMessage()
      );
    }

    if (showVehicleSetDetail) {
      const vehicleSetDetail = getVehicleSetDetail(
        searchResult.vehicleSets,
        vehicleSetId,
      );

      return vehicleSetDetail ? (
        <VehicleSetDetails
          isOpen
          vehicleSetId={vehicleSetId}
          key={vehicleSetId}
          data={vehicleSetDetail}
          onClickOpen={() => {}}
          onClickBack={() => {
            history.goBack();
          }}
        />
      ) : (
        getEmptyListMessage()
      );
    }

    return (
      <>
        {!!activities.length && (
          <ActivityList
            openDetails={openDetails}
            selectedDate={selectedDate}
            alarmFilteredActivities={alarmFilteredActivities}
          />
        )}

        {!!vehicleSets.length && (
          <VehicleSetList
            openDetails={openDetails}
            vehicleSetId={vehicleSetId}
            vehicleSets={vehicleSets}
          />
        )}
      </>
    );
  };

  return alarmFilteredActivities.length || vehicleSets.length ? (
    <DefaultDataLoadingContextProvider>
      <SearchResultsContainer data-testid='result-table'>
        {getViewContent()}
      </SearchResultsContainer>
    </DefaultDataLoadingContextProvider>
  ) : (
    displayErrorMessage(alarmFilters)
  );
};

export default SearchResultsList;
