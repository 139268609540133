import styled from 'styled-components';
import { blueGrey40 } from 'shared/styles/colors';

type SearchDetailsProps = {
  isOpen: Boolean;
};

const SearchDetailsContainer = styled.div<SearchDetailsProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6px;
  margin-top: 0;
  padding: ${(props): any => (props.isOpen ? '12px 0px' : '12px')};
  box-sizing: border-box;
  border-radius: 3px;
  row-gap: 0.6rem;
  background-color: ${(props): any =>
    props.isOpen ? 'transparent' : props.theme.primaryBackground};
  cursor: ${(props) => (props.isOpen ? 'default' : 'pointer')};
  border: ${(props) =>
    props.isOpen ? 'none' : `1px solid ${props.theme.defaultCardBorderColor}`};
`;

const SearchDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchDetailsSubContainer = styled.div`
  display: flex;
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 14px;
  border-color: ${blueGrey40};
`;

const TimeFieldContainer = styled.div`
  display: flex;
`;

export {
  SearchDetailsContainer,
  SearchDetailsSubContainer,
  SearchDetailsWrapper,
  BackButtonContainer,
  TimeFieldContainer,
};
